import {
  DecimalPlaces,
  MarketConfig,
  NegativePatterns,
  NumberFormatSeparators,
  Pattern,
  PositivePatterns,
  Sign,
} from './types';

const marketConfig: MarketConfig = require('../config/marketConfig');

const { _DEFAULT, VNM, IDN } = marketConfig;

interface CurrencyConfig {
  default: {
    decimalPlaces: DecimalPlaces;
    numberFormat: NumberFormatSeparators;
    pattern: Pattern;
  };
  [key: string]: Partial<CurrencyConfig['default']> & {
    sign?: Sign;
  };
}

const currencyConfig: CurrencyConfig = {
  default: {
    decimalPlaces: DecimalPlaces.TWO,
    numberFormat: _DEFAULT.numberFormatSeparators,
    // sign: should return currency string by default
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.MINUS_SIGN_AMOUNT,
    },
  },
  AED: {},
  AFN: {
    sign: {
      domestic: '؋',
    },
  },
  ALL: {
    sign: {
      domestic: 'Lek',
    },
  },
  AMD: {},
  ANG: {
    sign: {
      domestic: 'ƒ',
    },
  },
  AOA: {},
  ARS: {
    sign: {
      domestic: '$',
    },
  },
  AUD: {
    sign: {
      domestic: '$',
      global: 'AU$',
    },
  },
  AWG: {
    sign: {
      domestic: 'ƒ',
    },
  },
  AZN: {
    sign: {
      domestic: 'ман',
    },
  },
  BAM: {
    sign: {
      domestic: 'KM',
    },
  },
  BBD: {
    sign: {
      domestic: '$',
    },
  },
  BDT: {},
  BGN: {
    sign: {
      domestic: 'лв',
    },
  },
  BHD: {
    decimalPlaces: DecimalPlaces.THREE,
  },
  BIF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  BMD: {
    sign: {
      domestic: '$',
    },
  },
  BND: {
    sign: {
      domestic: '$',
    },
  },
  BOB: {
    sign: {
      domestic: '$b',
    },
  },
  BOV: {},
  BRL: {
    sign: {
      domestic: 'R$',
    },
  },
  BSD: {
    sign: {
      domestic: '$',
    },
  },
  BTN: {},
  BWP: {
    sign: {
      domestic: 'P',
    },
  },
  BYN: {
    sign: {
      domestic: 'Br',
    },
  },
  BZD: {
    sign: {
      domestic: 'BZ$',
    },
  },
  CAD: {
    sign: {
      domestic: '$',
      global: 'CA$',
    },
  },
  CDF: {},
  CHE: {},
  CHF: {
    sign: {
      domestic: 'CHF',
    },
  },
  CHW: {},
  CLF: {
    decimalPlaces: DecimalPlaces.FOUR,
  },
  CLP: {
    decimalPlaces: DecimalPlaces.ZERO,
    sign: {
      domestic: '$',
    },
  },
  CNY: {
    sign: {
      domestic: '¥',
      global: 'CNY',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.SIGN_MINUS_AMOUNT,
    },
  },
  COP: {
    sign: {
      domestic: '$',
    },
  },
  COU: {},
  CRC: {
    sign: {
      domestic: '₡',
    },
  },
  CUC: {},
  CUP: {
    sign: {
      domestic: '₱',
    },
  },
  CVE: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  CZK: {
    sign: {
      domestic: 'Kč',
    },
  },
  DJF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  DKK: {
    sign: {
      domestic: 'kr',
    },
  },
  DOP: {
    sign: {
      domestic: 'RD$',
    },
  },
  DZD: {},
  EGP: {
    sign: {
      domestic: '£',
    },
  },
  ERN: {},
  ETB: {},
  EUR: {
    sign: {
      domestic: '€',
      global: '€',
    },
    pattern: {
      positive: PositivePatterns.AMOUNT_SPACE_SIGN,
      negative: NegativePatterns.MINUS_AMOUNT_SPACE_SIGN,
    },
  },
  FJD: {
    sign: {
      domestic: '$',
    },
  },
  FKP: {
    sign: {
      domestic: '£',
    },
  },
  GBP: {
    sign: {
      domestic: '£',
      global: '£',
    },
  },
  GEL: {},
  GGP: {
    sign: {
      domestic: '£',
    },
  },
  GHS: {
    sign: {
      domestic: '¢',
    },
  },
  GIP: {
    sign: {
      domestic: '£',
    },
  },
  GMD: {},
  GNF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  GTQ: {
    sign: {
      domestic: 'Q',
    },
  },
  GYD: {
    sign: {
      domestic: '$',
    },
  },
  HKD: {
    sign: {
      domestic: '$',
      global: 'HK$',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
  },
  HNL: {
    sign: {
      domestic: 'L',
    },
  },
  HRK: {
    sign: {
      domestic: 'kn',
    },
  },
  HTG: {},
  HUF: {
    sign: {
      domestic: 'Ft',
    },
  },
  IDR: {
    sign: {
      domestic: 'Rp',
      global: 'Rp',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
    decimalPlaces: DecimalPlaces.ZERO,
    numberFormat: IDN.numberFormatSeparators,
  },
  ILS: {
    sign: {
      domestic: '₪',
    },
  },
  IMP: {
    sign: {
      domestic: '£',
    },
  },
  INR: {
    sign: {
      domestic: '₹',
    },
    pattern: {
      positive: PositivePatterns.SIGN_SPACE_AMOUNT,
      negative: NegativePatterns.SIGN_SPACE_MINUS_AMOUNT,
    },
  },
  IQD: {
    decimalPlaces: DecimalPlaces.THREE,
  },
  IRR: {
    sign: {
      domestic: '﷼',
    },
  },
  ISK: {
    decimalPlaces: DecimalPlaces.ZERO,
    sign: {
      domestic: 'kr',
    },
  },
  JEP: {
    sign: {
      domestic: '£',
    },
  },
  JMD: {
    sign: {
      domestic: 'J$',
    },
  },
  JOD: {
    decimalPlaces: DecimalPlaces.THREE,
  },
  JPY: {
    decimalPlaces: DecimalPlaces.ZERO,
    sign: {
      domestic: '円',
      global: '¥',
    },
  },
  KES: {},
  KGS: {
    sign: {
      domestic: 'лв',
    },
  },
  KHR: {
    sign: {
      domestic: '៛',
    },
  },
  KMF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  KPW: {
    sign: {
      domestic: '₩',
    },
  },
  KRW: {
    decimalPlaces: DecimalPlaces.ZERO,
    sign: {
      domestic: '₩',
      global: '₩',
    },
  },
  KWD: {
    decimalPlaces: DecimalPlaces.THREE,
  },
  KYD: {
    sign: {
      domestic: '$',
    },
  },
  KZT: {
    sign: {
      domestic: 'лв',
    },
  },
  LAK: {
    sign: {
      domestic: '₭',
    },
  },
  LBP: {
    sign: {
      domestic: '£',
    },
  },
  LKR: {
    sign: {
      domestic: '₨',
    },
  },
  LRD: {
    sign: {
      domestic: '$',
    },
  },
  LSL: {},
  LYD: {
    decimalPlaces: DecimalPlaces.THREE,
  },
  MAD: {},
  MDL: {},
  MGA: {
    decimalPlaces: DecimalPlaces.ONE,
  },
  MKD: {
    sign: {
      domestic: 'ден',
    },
  },
  MMK: {},
  MNT: {
    sign: {
      domestic: '₮',
    },
  },
  MOP: {
    sign: {
      domestic: 'MOP',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
  },
  MRO: {
    decimalPlaces: DecimalPlaces.ONE,
  },
  MUR: {
    sign: {
      domestic: '₨',
    },
  },
  MVR: {},
  MWK: {},
  MXN: {
    sign: {
      domestic: '$',
    },
  },
  MXV: {},
  MYR: {
    sign: {
      domestic: 'RM',
      global: 'RM',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
  },
  MZN: {
    sign: {
      domestic: 'MT',
    },
  },
  NAD: {
    sign: {
      domestic: '$',
    },
  },
  NGN: {
    sign: {
      domestic: '₦',
    },
  },
  NIO: {
    sign: {
      domestic: 'C$',
    },
  },
  NOK: {
    sign: {
      domestic: 'kr',
    },
  },
  NPR: {
    sign: {
      domestic: '₨',
    },
  },
  NZD: {
    sign: {
      domestic: '$',
      global: 'NZ$',
    },
  },
  OMR: {
    decimalPlaces: DecimalPlaces.THREE,
    sign: {
      domestic: '﷼',
    },
  },
  PAB: {
    sign: {
      domestic: 'B/.',
    },
  },
  PEN: {
    sign: {
      domestic: 'S/.',
    },
  },
  PGK: {},
  PHP: {
    sign: {
      domestic: 'PhP',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
  },
  PKR: {
    sign: {
      domestic: '₨',
    },
  },
  PLN: {
    sign: {
      domestic: 'zł',
    },
  },
  PYG: {
    decimalPlaces: DecimalPlaces.ZERO,
    sign: {
      domestic: 'Gs',
    },
  },
  QAR: {
    sign: {
      domestic: '﷼',
    },
  },
  RON: {
    sign: {
      domestic: 'lei',
    },
  },
  RSD: {
    sign: {
      domestic: 'Дин.',
    },
  },
  RUB: {
    sign: {
      domestic: '₽',
    },
  },
  RWF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  SAR: {
    sign: {
      domestic: '﷼',
    },
  },
  SBD: {
    sign: {
      domestic: '$',
    },
  },
  SCR: {
    sign: {
      domestic: '₨',
    },
  },
  SDG: {},
  SEK: {
    sign: {
      domestic: 'kr',
    },
  },
  SGD: {
    sign: {
      domestic: '$',
      global: 'S$',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
  },
  SHP: {
    sign: {
      domestic: '£',
    },
  },
  SLL: {},
  SOS: {
    sign: {
      domestic: 'S',
    },
  },
  SRD: {
    sign: {
      domestic: '$',
    },
  },
  SSP: {},
  STD: {},
  SVC: {
    sign: {
      domestic: '$',
    },
  },
  SYP: {
    sign: {
      domestic: '£',
    },
  },
  SZL: {},
  THB: {
    sign: {
      domestic: '฿',
      global: '฿',
    },
  },
  TJS: {},
  TMT: {},
  TND: {
    decimalPlaces: DecimalPlaces.THREE,
  },
  TOP: {},
  TRY: {
    sign: {
      domestic: '₺',
    },
  },
  TTD: {
    sign: {
      domestic: 'TT$',
    },
  },
  TVD: {
    sign: {
      domestic: '$',
    },
  },
  TWD: {
    sign: {
      domestic: '$',
      global: 'NT$',
    },
    decimalPlaces: DecimalPlaces.ZERO,
  },
  TZS: {},
  UAH: {
    sign: {
      domestic: '₴',
    },
  },
  UGX: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  USD: {
    sign: {
      domestic: '$',
      global: 'US$',
    },
    pattern: {
      positive: PositivePatterns.SIGN_AMOUNT,
      negative: NegativePatterns.PARANTHESES_SIGN_AMOUNT,
    },
  },
  USN: {},
  UYI: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  UYU: {
    sign: {
      domestic: '$U',
    },
  },
  UZS: {
    sign: {
      domestic: 'лв',
    },
  },
  VEF: {
    sign: {
      domestic: 'Bs',
    },
  },
  VND: {
    decimalPlaces: DecimalPlaces.ZERO,
    numberFormat: VNM.numberFormatSeparators,
    sign: {
      domestic: '₫',
    },
    pattern: {
      positive: PositivePatterns.AMOUNT_SPACE_SIGN,
      negative: NegativePatterns.MINUS_AMOUNT_SPACE_SIGN,
    },
  },
  VUV: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  WST: {},
  XAF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  XCD: {
    sign: {
      domestic: '$',
    },
  },
  XOF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  XPF: {
    decimalPlaces: DecimalPlaces.ZERO,
  },
  YER: {
    sign: {
      domestic: '﷼',
    },
  },
  ZAR: {
    sign: {
      domestic: 'R',
    },
  },
  ZMW: {},
  ZWD: {
    sign: {
      domestic: 'Z$',
    },
  },
  ZWL: {},
};

export default currencyConfig;
