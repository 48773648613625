import { Action, Location } from 'history';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { locationChangeBegin, locationChangeEnd, setBaseUrl, setHost } from './actions';

const initialLocation: {
  location: Location;
  action?: Action;
  protocol?: string;
  isPending: boolean;
} = {
  location: {
    pathname: '/',
    search: '',
    hash: '',
    state: {},
  },
  isPending: false,
};

export const location = createReducer(initialLocation)
  // XXX: we don't update location object at the beginning of location change, because before
  // we are ready to render next page, if we change the location object the current page might
  // get re-rendered unexpectedly
  .handleAction(locationChangeBegin, (state, action) => ({
    ...state,
    isPending: true,
  }))
  .handleAction(locationChangeEnd, (state, action) => ({
    location: action.payload.location,
    action: action.payload.action,
    protocol: action.payload.protocol || state.protocol,
    isPending: false,
  }));

export const baseUrl = createReducer('').handleAction(
  setBaseUrl,
  (_state, action) => action.payload,
);

export const host = createReducer('').handleAction(setHost, (_state, action) => action.payload);

const routerReducer = combineReducers({
  location,
  baseUrl,
  host,
});

export default routerReducer;
export type RouterState = ReturnType<typeof routerReducer>;
