import React from 'react';
import { useSelector } from 'react-redux';

import { DecimalValue } from '../config/types';
import { getMarket } from '../selectors';
import {
  formatNumber,
  getCurrencyNumberFormatSeparators,
  getNumberFormatSeparators,
} from '../utils/l10n';

type Props = {
  market?: string;
  value: DecimalValue;
  decimals?: number;
  locale?: string;
};

const DEFAULT_DECIMALS = 0;

/**
 * Format number based on currency
 */
function getL10nCurrencyNumber(
  currency: string,
  value: DecimalValue,
  decimals = DEFAULT_DECIMALS,
  shouldDisplayOriginalValue = false,
) {
  const separators = getCurrencyNumberFormatSeparators(currency);
  return formatNumber(value, decimals, shouldDisplayOriginalValue, separators);
}

/**
 * Format number based on market
 */
function getL10nNumber(
  market: string,
  value: DecimalValue,
  decimals = DEFAULT_DECIMALS,
  shouldDisplayOriginalValue = false,
) {
  const separators = getNumberFormatSeparators(market);
  return formatNumber(value, decimals, shouldDisplayOriginalValue, separators);
}

function L10nNumber({ market, value, decimals = DEFAULT_DECIMALS }: Props) {
  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  return !value && value !== 0 ? null : (
    <span>{getL10nNumber(market || marketFromStore, value, decimals)}</span>
  );
}

export default L10nNumber;

export { getL10nNumber, getL10nCurrencyNumber };
