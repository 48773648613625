import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { fetch } from './api';

import generateLogger from '@travel/logger';

const logger = generateLogger();

export const fetchBannerAsync = createAsyncAction(
  'FETCH_BANNER_REQUEST',
  'FETCH_BANNER_SUCCESS',
  'FETCH_BANNER_FAILURE',
)<undefined, any, undefined>();

export const fetchBanner = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  const { authCode, banner } = getState();
  // do not fetch if there is no token or data has been fetched
  if (!authCode.item?.token || banner?.item?.titleBackgroundColor) return;

  dispatch(fetchBannerAsync.request());
  try {
    const response = await fetch(apiClient);
    dispatch(fetchBannerAsync.success(response));
  } catch (error) {
    logger.error(error);
    dispatch(fetchBannerAsync.failure());
  }
};
