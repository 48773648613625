import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchAuthCodeAsync, logoutAsync } from './actions';

import { AuthCodeError, AuthCodeItem } from 'AuthCode-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchAuthCodeAsync.request], () => true)
  .handleAction([fetchAuthCodeAsync.success, fetchAuthCodeAsync.failure], () => false);

export const item = createReducer({} as AuthCodeItem)
  .handleAction(fetchAuthCodeAsync.success, (_state, action) => action.payload)
  .handleAction(fetchAuthCodeAsync.failure, () => ({} as AuthCodeItem))
  .handleAction(logoutAsync.success, (_state, action) => action.payload);

export const error = createReducer({} as AuthCodeError)
  .handleAction(fetchAuthCodeAsync.failure, (_state, action) => action.payload)
  .handleAction(fetchAuthCodeAsync.success, () => ({} as AuthCodeError));

const authCodeReducer = combineReducers({
  isFetching,
  item,
  error,
});

export default authCodeReducer;
export type AuthCodeState = ReturnType<typeof authCodeReducer>;
