import { useEffect } from 'preact/hooks';
import { useSelector } from 'react-redux';

import { loadScript } from '@travel/traveler-core/utils/script';

import { Status } from 'constants/reservations';

import { getItem } from 'store/pitari/selectors';
import { getCurrentTab } from 'store/reservationsList/selectors';

const ELEMENT_KEYS = {
  [Status.UPCOMING]: 'Upcoming',
  [Status.PAST]: 'Past',
  [Status.CANCELED]: 'Cancelled',
};

function Pitari() {
  const currentTab = useSelector(getCurrentTab);
  const item = useSelector(getItem);

  const tabScriptId = 'pitari-script';
  const phoenixScriptId = 'phoenix-script';
  const lowercasedKey = ELEMENT_KEYS[currentTab].toLowerCase();
  const scriptId = item?.[`pitari_test_${lowercasedKey}`];

  // effect to add Pitari scripts if script ids exist
  useEffect(() => {
    if (scriptId) {
      const currentTabScript = document.querySelector(`#${tabScriptId}`);
      currentTabScript?.parentElement?.removeChild(currentTabScript);
      const currentPhoenixScriptId = document.querySelector(`#${phoenixScriptId}`);
      currentPhoenixScriptId?.parentElement?.removeChild(currentPhoenixScriptId);

      loadScript(
        `//www.rakuten.co.jp/com/advance/travel/Travel_MyPage_${ELEMENT_KEYS[currentTab]}/responsive/${scriptId}/condition.js`,
        tabScriptId,
      ).then(() => {
        // we have to re-fetch phoenix every time the pitari content has been changed
        setTimeout(() => {
          loadScript('//r.r10s.jp/com/ap/target/phoenix-2.8.1.min.js', phoenixScriptId);
        }, 1000);
      });
    }
  }, [currentTab, scriptId]);

  return scriptId ? (
    <div
      data-testid="pitari-wrapper"
      key={lowercasedKey}
      id={`bnr_${lowercasedKey}`}
      {...{ [`data-phoenix-travel_mypage_${lowercasedKey}_responsive`]: true }}
    />
  ) : null;
}

export default Pitari;
