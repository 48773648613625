import { Details } from 'express-useragent';

export function getDeviceType(userAgent: Details | null | undefined) {
  if (!userAgent) {
    return 'sp';
    /**
     * isTablet and isMobile are both true for iPad device due to the library validation.
     * (refer to https://github.com/biggora/express-useragent/blob/8aa2042f55655bb44c4b4695c31d4ee3e24baea2/lib/express-useragent.js#L714)
     */
  } else if (userAgent.isTablet) {
    return 'tl';
  } else if (userAgent.isMobile) {
    return 'sp';
  } else {
    return 'pc';
  }
}
