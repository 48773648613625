const editableContentsCache = new Map();
const timeoutValue = 60000; // 1 min

export const setEditableContents = <T = any>(key: string, value: T) => {
  editableContentsCache.set(key, value);
  setTimeout(() => {
    editableContentsCache.delete(key);
  }, timeoutValue);
};

export const getEditableContents = <T = any>(key: string): T | undefined => {
  return editableContentsCache.get(key);
};

export const deleteEditableContentsKey = <T = any>(key: string) => {
  return editableContentsCache.delete(key);
};

export const clearEditableContents = () => {
  return editableContentsCache.clear();
};
