import React from 'react';
import { connect } from 'react-redux';
import {
  parse as parsePhoneNumber,
  format as formatPhoneNumber,
  getPhoneCode,
  CountryCallingCode,
} from 'libphonenumber-js';

import { getSupportedMarkets, getCountryCodeIsoAlpha2FromIsoAlpha3 } from '../utils/l10n';

type Props = {
  value: string;
  country: string;
};

const DEFAULT_COUNTRY = 'USA';

function getPhoneNumberPrefix(country = DEFAULT_COUNTRY) {
  const countryCodeIsoAlpha2 = getCountryCodeIsoAlpha2FromIsoAlpha3(country);
  return getPhoneCode(countryCodeIsoAlpha2);
}

/**
 * This function return a list of phone number prefixes for the supported countries and
 * also take care of removing duplicated like (+1) for USA and (+1) for Canada.
 *
 * This first implementation do not include any market information related to the phone
 * prefix since is not specified in the current requirements.
 *
 * @returns {Array} list of prefixes for list of supported market
 */
function getPhoneNumberPrefixes() {
  const markets = getSupportedMarkets();
  const prefixes: CountryCallingCode[] = [];
  markets.forEach(market => {
    const prefix = getPhoneNumberPrefix(market);
    if (prefixes.indexOf(prefix) === -1) {
      prefixes.push(prefix);
    }
  });
  return prefixes;
}

function getCountryCallingCode(market: string) {
  return getPhoneNumberPrefix(market);
}

function getL10nPhone(phoneNumber: string, country = DEFAULT_COUNTRY) {
  const countryCodeIsoAlpha2 = getCountryCodeIsoAlpha2FromIsoAlpha3(country);
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, countryCodeIsoAlpha2);
  return formatPhoneNumber(parsedPhoneNumber, 'International');
}

function L10nPhone({ value, country }: Props) {
  const formattedNumber = getL10nPhone(value, country);
  if (formattedNumber.length === 0) {
    return <span>{value}</span>;
  }
  return <span>{formattedNumber}</span>;
}

L10nPhone.defaultProps = {
  country: DEFAULT_COUNTRY,
};

function mapStateToProps(state: any, ownProps: Props) {
  return {
    ...ownProps,
    locale: state._i18n.localeForOther,
  };
}

export default connect(mapStateToProps)(L10nPhone);

export { getPhoneNumberPrefix, getPhoneNumberPrefixes, getCountryCallingCode };
