import './dayjsExtensions/dayJsLocale';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { useSelector } from 'react-redux';

import localeData from 'dayjs/plugin/localeData'; // dependent on utc plugin
import localizedFormat from 'dayjs/plugin/localizedFormat'; // dependent on utc plugin
import updateLocale from 'dayjs/plugin/updateLocale'; // dependent on utc plugin
import { getDayjsLocale } from './dayjsExtensions/constants';
import parseZone from './dayjsExtensions/parseZone';

import { getLanguage, getMarket } from '../selectors';
import { getCustomDateTimeFormat, getFirstDayOfWeek, getLocaleMeridiems } from '../utils/l10n';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(parseZone);

const localeMeridiems = getLocaleMeridiems();
Object.entries(localeMeridiems).forEach(([language, meridiemFnObj]) => {
  // dayjs uses lowercase language codes
  const languageLowerCased = language.toLowerCase();

  meridiemFnObj && dayjs.updateLocale(languageLowerCased, meridiemFnObj);
});

const DEFAULT_MOMENT_FORMAT = 'lll';
const DEFAULT_DISPLAY_AS_LOCAL_TIME = false;

export const ISO_8601_DATE = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const ISO_8601_SLASH_DATE = 'YYYY/MM/DD';
export const ISO_8601_DASH_DATE = 'YYYY-MM-DD';
export const SUPPLIER_TIME_FORMAT = 'LT';
export const SUPPLIER_STANDARD_DATE = 'L';
export const SUPPLIER_STANDARD_DATE_TIME = 'L LTS';
export const SUPPLIER_SHORT_DATE_TIME = 'L LT';
export const SUPPLIER_LONG_DATE_TIME = 'L (ddd) LT';
export const SUPPLIER_MONTH_DAY = 'MM/DD (ddd)';
export const SUPPLIER_OTHER_DATE_TIME = 'L HH:mm';
export const SUPPLIER_YEAR = 'YYYY';
export const SUPPLIER_DAY_HEADER = 'ddd';
export const SUPPLIER_DAY = 'D';
export const SUPPLIER_MONTH = 'MMM';
export const SUPPLIER_YEAR_SLASH_MONTH = 'YYYY/MMMM';
export const SUPPLIER_24H_TIME = 'HH:mm:ss';
export const SUPPLIER_24H_TIME_CONSTRUCTOR = 'HH:mm';
// https://momentjs.com/docs/#/displaying/
// https://confluence.rakuten-it.com/confluence/display/EVEREST/%28V1.0%291.5.+Localization+of+the+Site
export const SHORT_DATE = 'L';
export const SHORT_DATE_LONG_TIME = 'L LTS';
export const SHORT_DATE_SHORT_TIME = 'L LT';
export const SHORT_TIME = 'LT';
export const LONG_TIME = 'LTS';
export const SHORT_DATE_DAY = 'L (ddd)';
export const SHORT_DATE_TIME_DAY = 'L (ddd) LT';
export const SHORT_DATE_DAY_LONG_TIME = 'L (ddd) LTS';
export const SHORT_YEAR_MONTH = 'YYYY/MM';
export const SUPPLIER_MONTH_YEAR = 'MMMM YYYY';
export const ISO_8601_ZONE_DATE = 'YYYY-MM-DDTHH:mm:ssZ';

type Props = {
  market?: string;
  language?: string;
  locale?: string;
  value: string;
  format: string;
  isDisplayAsLocalTime: boolean;
};

export const setGlobalDayjsLocal = (language: string) => {
  const locale = getDayjsLocale(language);
  dayjs.locale(locale);
};

export const getL10nDateFormat = (language: string, market: string, format: string) => {
  const customFormatMap = getCustomDateTimeFormat(language, market);

  const localFormat = format
    .replace('LTS', customFormatMap.LTS)
    .replace('LT', customFormatMap.LT)
    .replace('LLL', customFormatMap.LLL)
    .replace('LL', customFormatMap.LL)
    .replace('L', customFormatMap.L)
    .replace('MD', customFormatMap.MD)
    .replace('YM', customFormatMap.YM);

  return localFormat;
};

function getL10nDate(
  language: string,
  value: string,
  format = DEFAULT_MOMENT_FORMAT,
  isDisplayAsLocalTime = DEFAULT_DISPLAY_AS_LOCAL_TIME,
  market: string,
) {
  const localFormat = getL10nDateFormat(language, market, format);
  const dayjsObj = isDisplayAsLocalTime ? dayjs(value) : dayjs.parseZone(value);
  const locale = getDayjsLocale(language);

  // one-off: when language is english but market is vietnamese, we will use SA CH instead of a.m. p.m.
  if (language === 'en-US') {
    let englishLocaleMeridiem = localeMeridiems['en-US'];
    if (market === 'VNM') {
      englishLocaleMeridiem = localeMeridiems['vi-VN'];
    }
    // this part is required for UTs to pass
    dayjs.updateLocale('en', englishLocaleMeridiem);
  }

  return dayjsObj.locale(locale).format(localFormat);
}

export function L10nDate({
  language = '',
  market = '',
  value,
  format = DEFAULT_MOMENT_FORMAT,
  isDisplayAsLocalTime = DEFAULT_DISPLAY_AS_LOCAL_TIME,
}: Props) {
  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  const languageFromStore = useSelector(getLanguage) || '';
  return (
    <span>
      {getL10nDate(
        language || languageFromStore,
        value,
        format,
        isDisplayAsLocalTime,
        market || marketFromStore,
      )}
    </span>
  );
}

L10nDate.defaultProps = {
  format: DEFAULT_MOMENT_FORMAT,
  isDisplayAsLocalTime: DEFAULT_DISPLAY_AS_LOCAL_TIME,
};

export const useLocaleWeekAndMonth = () => {
  const marketFromStore = useSelector(getMarket).marketCode || '';
  const languageFromStore = useSelector(getLanguage) || '';
  const locale = getDayjsLocale(languageFromStore);
  dayjs.locale(locale); // Keeping previous code. TODO: use setGlobalLocal() on top of application (Traveler)

  return {
    weekdays: dayjs.localeData().weekdays(),
    weekdaysShort: dayjs.localeData().weekdaysShort(),
    months: dayjs.localeData().months(),
    monthsShort: dayjs.localeData().monthsShort(),
    firstDayOfWeek: getFirstDayOfWeek(marketFromStore),
  };
};

export default L10nDate;

export { getL10nDate };
