import React from 'react';
import { useSelector } from 'react-redux';
import { getMarket } from '../selectors';
import { getL10nNumber } from './L10nNumber';

type Props = {
  value: string | number;
  unit?: string;
  unitSyntax?: UnitSyntax;
  market?: string;
  decimals?: number;
};

const DEFAULT_DECIMALS = 0; // round to 0 decimals places

const Power2 = ({ unit }: { unit: string }) => (
  <>
    {unit}
    <sup style={{ verticalAlign: 'super', fontSize: 'smaller' }}>2</sup>
  </>
);

const UNITS = {
  m: ` m`,
  m2: <Power2 unit=" m" />,
  cm: ` cm`,
  cm2: <Power2 unit=" cm" />,
  ft: ` ft`,
  ft2: <Power2 unit=" ft" />,
} as const;

type UnitSyntax = keyof typeof UNITS;

/** Format measurement data with decimal.
 * if no unit info, create unit from unixSyntax.
 */
function L10nMeasurement(props: Props) {
  const { value, unit, unitSyntax, market, decimals = DEFAULT_DECIMALS } = props;

  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  return (
    <span>
      {getL10nNumber(market || marketFromStore, value, decimals)}
      {unit ? ` ${unit}` : unitSyntax && UNITS[unitSyntax]}
    </span>
  );
}

export default L10nMeasurement;
