import { createSelector } from 'reselect';

import { RanStore } from './types';

export const getRanStore = (state: { ranStore: RanStore }) => state.ranStore;

export const getRanData = createSelector(getRanStore, state => state.ranData);

export const getShortUrl = createSelector(getRanStore, state => state.shortUrl);

export const getShortUrl_iOS = createSelector(getShortUrl, state => state.iOS);

export const getShortUrl_Android = createSelector(getShortUrl, state => state.android);
