import env from '@travel/env';

import { AddCouponRequestBody, CouponSearchRequestBody } from 'FurusatoNozei-Types';

export const getCouponListAPI = async (params: CouponSearchRequestBody): Promise<Response> => {
  const body = `${encodeURIComponent('reservationId')}=${encodeURIComponent(params.reservationId)}`;
  return fetch(`${env('UNIVERSAL_API_CP_URL_SERVER')}/mypage/hometowntax/searchCoupon.do`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    body,
  });
};

export const postCouponAPI = async (params: AddCouponRequestBody): Promise<Response> => {
  const body = Object.keys(params)

    .reduce((formattedBody: Array<string>, key) => {
      formattedBody.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
      return formattedBody;
    }, [])
    .join('&');
  return fetch(`${env('UNIVERSAL_API_CP_URL_SERVER')}/mypage/hometowntax/addCoupon.do`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    body,
  });
};
