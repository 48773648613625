import url from 'url';

import { ApiClient } from '@travel/api-client';
import { Dictionary } from '@travel/translation';

import supportedMarkets from '@tns/lang/src/supported-markets.json';
import { MarketType, SupportedLanguageType } from '../';
import { getLanguage, getMarket } from '../selectors/index';
import getLanguageFromUrl from '../utils/getLanguageFromUrl';
import getLanguageSupportedWithLocale from '../utils/getLanguageSupportedWithLocale';
import getLocaleSupportedForL10nDates from '../utils/getLocaleSupportedForL10nDates';
import getLocaleSupportedForL10nOther from '../utils/getLocaleSupportedForL10nOther';
import getMarketFromUrl from '../utils/getMarketFromUrl';

// action types

export const SET_DICTIONARY = '[@travel/i18n] SET_DICTIONARY';
export const SET_DICTIONARY_ALL_LANGUAGE = '[@travel/i18n] SET_DICTIONARY_ALL_LANGUAGE';
export const SET_TERMS = '[@travel/i18n] SET_TERMS';
export const SET_LABELS = '[@travel/i18n] SET_LABELS';
export const SET_VARIANT_DICTIONARY = '[@travel/i18n] SET_VARIANT_DICTIONARY';
export const SET_ALL_VARIANT_DICTIONARY = '[@travel/i18n] SET_ALL_VARIANT_DICTIONARY';
export const SET_SUPPORTED_LANGUAGES = '[@travel/i18n] SET_SUPPORTED_LANGUAGES';
export const SET_FALLBACK_LANGUAGES = '[@travel/i18n] SET_FALLBACK_LANGUAGES';
export const SET_IS_GLOBAL_CURRENCY = '[@travel/i18n] SET_IS_GLOBAL_CURRENCY';

export const SET_LANGUAGE_SUPPORTED_FOR_I18N = '[@travel/i18n] SET_LANGUAGE_SUPPORTED_FOR_I18N';
export const SET_LOCALE_SUPPORTED_FOR_L10N_DATES =
  '[@travel/i18n] SET_LOCALE_SUPPORTED_FOR_L10N_DATES';
export const SET_LOCALE_SUPPORTED_FOR_L10N_OTHER =
  '[@travel/i18n] SET_LOCALE_SUPPORTED_FOR_L10N_OTHER';

export const SET_USER_MARKET = '[@travel/i18n] SET_USER_MARKET';
export const SET_CURRENCY = '[@travel/i18n] SET_CURRENCY';

// sync action creator
export function setMarket(market: MarketType) {
  return { type: SET_USER_MARKET, market };
}

export function setTerms(terms: Object) {
  return { type: SET_TERMS, payload: terms };
}

export function setLabels(labels: Object) {
  return { type: SET_LABELS, payload: labels };
}

export function setDictionary(dictionary: Dictionary) {
  return { type: SET_DICTIONARY, dictionary };
}

export function setAllDictionary(allDictionary: Object) {
  return { type: SET_DICTIONARY_ALL_LANGUAGE, allDictionary };
}

export function setVariantDictionary(variantDictionary: Object | undefined) {
  return { type: SET_VARIANT_DICTIONARY, variantDictionary };
}

export function setAllVariantDictionary(allVariantDictionary: Object) {
  return { type: SET_ALL_VARIANT_DICTIONARY, allVariantDictionary };
}

export function setSupportedLanguages(supportedLanguages: SupportedLanguageType[]) {
  return { type: SET_SUPPORTED_LANGUAGES, supportedLanguages };
}

export function setFallbackLanguages(fallbackLanguages: string[]) {
  return { type: SET_FALLBACK_LANGUAGES, fallbackLanguages };
}

export function setCurrentLanguage(language: string) {
  return { type: SET_LANGUAGE_SUPPORTED_FOR_I18N, language };
}
export function setCurrency(currency: string) {
  return { type: SET_CURRENCY, currency };
}

export function setIsGlobalCurrency(isGlobalCurrency: boolean) {
  return { type: SET_IS_GLOBAL_CURRENCY, isGlobalCurrency };
}

// async action creators

export function initializeLanguageAndMarket(
  browserAcceptLanguage: string,
  requestUrl = '',
  alliance = null,
  affiliate = null,
  authToken = null,
  isCrawler = null,
) {
  return (dispatch: Function, getState: Function) => {
    const { _i18n } = getState();

    const language =
      isCrawler && requestUrl ? getLanguageFromUrl(requestUrl) : browserAcceptLanguage;

    const market = requestUrl ? getMarketFromUrl(requestUrl) : null;

    const {
      language: languageSupportedForI18n,
      locale: localeSupportedByBrowser,
    } = getLanguageSupportedWithLocale(
      language,
      isCrawler ? ['en-US', 'ja'] : _i18n.supportedLanguages,
    );

    dispatch({ type: SET_LANGUAGE_SUPPORTED_FOR_I18N, language: languageSupportedForI18n });

    const specificMarket = requestUrl
      ? url.parse(requestUrl, true).pathname!.split('/')[1] || ''
      : '';
    const queryMarket = supportedMarkets.includes(specificMarket) ? specificMarket : '';

    //TODO The initializeLanguageAndMarket can be added in appliction side  so that  we can remove this part of code in feature
    const apiClient = new ApiClient({
      baseURLServer: 'http://serever-url',
      baseURLClient: 'http://serever-url',
    });

    //NOTE: fetch market API accepts country params (case insensitive ) and return market in UPPER CASE always

    return apiClient
      .fetchApi('travel/consumer/v1/market', {
        method: 'GET',
        headers: {
          'Accept-Language': language,
          country: market,
          Authorization: authToken,
          alliance,
          affiliate,
        },
        values: { country: queryMarket },
      })
      .then(response => {
        dispatch({ type: SET_USER_MARKET, market: response });
        const state = getState();
        const language = getLanguage(state);
        const market = getMarket(state);
        const locale = localeSupportedByBrowser;

        const localeSupportedForL10nDates = getLocaleSupportedForL10nDates(
          language,
          locale,
          market,
        );
        dispatch({
          type: SET_LOCALE_SUPPORTED_FOR_L10N_DATES,
          locale: localeSupportedForL10nDates,
        });

        const localeSupportedForL10nOther = getLocaleSupportedForL10nOther(market);
        dispatch({
          type: SET_LOCALE_SUPPORTED_FOR_L10N_OTHER,
          locale: localeSupportedForL10nOther,
        });
      })
      .catch(error => {
        // TODO Andres: decide a proper fallback or error message
        console.log(error);
      });
  };
}
