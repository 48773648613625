import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Details } from 'express-useragent';
import { getDeviceType } from '../utils';

export const MOBILE_SCREEN = 'sp';
export const TABLET_SCREEN = 'tl';
export const DESKTOP_SCREEN = 'pc';

export type DeviceType = 'sp' | 'tl' | 'pc';

/**
 * This custom hook use window.matchMedia and returns proper device type (sp, tl, pc) based on window width.
 * On SSR, it just simply use the value from DeviceTypeContext. Usually you can get the proper device type from useragent
 * and pass it down through context.
 */
function useDeviceType() {
  const ssrDeviceType = useSelector((store: { userAgent?: { userAgent?: Details } }) =>
    getDeviceType(store?.userAgent?.userAgent),
  );
  const [deviceType, setDeviceType] = useState((ssrDeviceType || 'sp') as DeviceType);

  useEffect(() => {
    if (!window.matchMedia) return;

    const mediaQueryTL = window.matchMedia('(max-width: 1024px)');
    const mediaQuerySP = window.matchMedia('(max-width: 743px)');

    function updateMediaQuery() {
      if (mediaQuerySP.matches) {
        setDeviceType(MOBILE_SCREEN);
      } else if (mediaQueryTL.matches) {
        setDeviceType(TABLET_SCREEN);
      } else {
        setDeviceType(DESKTOP_SCREEN);
      }
    }

    updateMediaQuery();

    mediaQueryTL.addListener(updateMediaQuery);
    mediaQuerySP.addListener(updateMediaQuery);

    return () => {
      mediaQueryTL.removeListener(updateMediaQuery);
      mediaQuerySP.removeListener(updateMediaQuery);
    };
  }, []);

  return deviceType;
}

export function useDeviceTypes() {
  const deviceType = useDeviceType();
  return {
    isPC: deviceType === DESKTOP_SCREEN,
    isSP: deviceType === MOBILE_SCREEN,
    isTL: deviceType === TABLET_SCREEN,
  };
}

export default useDeviceType;
