import { RootState } from 'typesafe-actions';

export const getCommonError = (state: RootState) => state.commonError;

export const getIsAPIError = (state: RootState) => {
  const statusCode = state.commonError?.item?.httpStatusCode;
  return statusCode && /^(4|5)/.test(String(statusCode));
};

export const getIsUIImplementationError = (state: RootState) =>
  state.commonError.isUIImplementationError;
