import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { getI18nArraySelector, LinkInfo } from '../selectors/index';

type Props = {
  children?: ReactNode;
  id: string;
  linkInfo?: { [key: string]: LinkInfo };
  variant?: { [key: string]: ReactNode };
  vKey?: string | Array<string>;
  data?: object;
};

const I18n: FC<Props> = ({ children, id }) => {
  return React.createElement('span', { 'data-object-id': id }, children);
};

function mapStateToProps(state: any, ownProps: Props) {
  return {
    children: React.Children.toArray(getI18nArraySelector(state, ownProps)),
    id: ownProps.id,
    vKey: ownProps.vKey,
    linkInfo: ownProps.linkInfo,
  };
}

export default connect(mapStateToProps)(I18n);
