import { isEmptyObject } from '@travel/utils';

import { lineBreak } from './allowedTags';

import { getDictionary } from './context';
import { Dictionary, TranslateProps } from './types';

/*
 * This function takes a string that represents a boolean operation and translates it into an actual boolean result.
 * The string can contain '==' or '>' operators and the operands can be either numbers or strings with true or false.
 */
function evaluateStringExpression(booleanOperation: string) {
  // If the input string is 'true' or 'false', return the corresponding boolean value
  if (booleanOperation === 'true') {
    return true;
  } else if (booleanOperation === 'false') {
    return false;
  }
  // Split the input string by '==' operator as a default
  let [first, second] = booleanOperation.split('==');
  let operator = booleanOperation.includes('==') ? '==' : '';

  // If the input string contains '>', split by '>' and update the operator
  if (booleanOperation.includes('>')) {
    [first, second] = booleanOperation.split('>');
    operator = '>';
  }

  // Try to parse the operands as numbers
  const firstNr = parseInt(first);
  const secondNr = parseInt(second);

  // Check if both operands are numbers
  const isNumber = !isNaN(firstNr) && !isNaN(secondNr);

  // If the operator is '==' and both operands are numbers, return the result of the numerical comparison
  if (operator === '==' && isNumber) {
    return firstNr === secondNr;
  }
  // If the operator is '==' and the operands are not numbers, return the result of the string comparison
  // first and second can be 'true' or 'false' strings
  else if (operator === '==') {
    return first.trim() === second.trim();
  }
  // If the operator is '>', return the result of the numerical comparison
  else if (operator === '>') {
    return firstNr > secondNr;
  }

  return false;
}

export const useTranslate = (props: TranslateProps) => {
  return getTranslation(props).join('');
};

export const getTranslation = (props: TranslateProps, storedDictionary?: Dictionary) => {
  const dictionary =
    storedDictionary && !isEmptyObject(storedDictionary) ? storedDictionary : getDictionary();
  const labelValue = getLabel(dictionary, props);
  if (labelValue) {
    const parts = checkAndSplitDataString(labelValue);
    return parts.map((value, index) => {
      if (index % 2 === 1) {
        const valueLowerCase = value.toLowerCase();
        return props.data && typeof props.data[valueLowerCase] !== 'undefined'
          ? props.data[valueLowerCase]
          : `{{${value}}}`;
      }
      if (value.includes('\\n')) {
        return lineBreak(value);
      }
      return value;
    });
  }
  return [];
};

export const getLabel = (
  dictionary: Dictionary,
  { id, condition, count, exist }: TranslateProps,
) => {
  if (!dictionary) return '';
  const selected = dictionary[id];
  if (!selected) return '';
  let index = 0;
  if (condition) {
    index = selected.findIndex(
      selectedCondition => selectedCondition.condition === condition(selected),
    );

    if (index === -1) return '';
  } else if (count !== undefined && count !== null) {
    const value = selected
      .filter(selectedCondition => {
        if (selectedCondition.condition.includes('count')) {
          const replaced = selectedCondition.condition.replace('count', count.toString());
          return evaluateStringExpression(replaced);
        }
        return false;
      })
      .map(condition => {
        if (!condition.labelValues) return '';
        return condition.labelValues[0].value;
      });
    return value[0];
  } else if (exist !== undefined) {
    const value = selected
      .filter(selectedCondition => {
        if (selectedCondition.condition.includes('exist')) {
          const replaced = selectedCondition.condition.replace('exist', exist.toString());
          return evaluateStringExpression(replaced);
        }
        return false;
      })
      .map(condition => {
        if (!condition.labelValues) return '';
        return condition.labelValues[0].value;
      });
    return value[0];
  }

  if (!selected[index].labelValues) return '';
  return selected[index].labelValues[0].value;
};

const checkAndSplitDataString = (value: string) => {
  return value.includes('{{') ? value.split(/{{([^}]+)}}/g) : value.split(/{([^}]+)}/g);
};
