import { getCookie, setCookie } from '@travel/utils';
import { MemberItem } from '../types/member';
import { DEVICE_ID } from '../constants/cookies';

export const getPreviousPurchase = () => {
  const pastPurchaseJson = getCookie('pastPurchaseOnDevice');

  try {
    return pastPurchaseJson ? JSON.parse(pastPurchaseJson) : {};
  } catch (error) {
    return {};
  }
};

export const setPreviousPurchase = (pastPurchaseObj: { [key: string]: string }) => {
  try {
    const expiresDate = new Date();
    expiresDate.setFullYear(expiresDate.getFullYear() + 5);
    const cookieVal = JSON.stringify(pastPurchaseObj);
    setCookie('pastPurchaseOnDevice', cookieVal, {
      sameSite: 'strict',
      secure: true,
      path: '/',
      expires: expiresDate,
    });
  } catch (error) {
    console.error({ error });
  }
};

export const getHasPastPurchaseOnDevice = (item: MemberItem) => {
  let hasPastPurchaseOnDevice: boolean = false;
  const pastPurchaseObj = getPreviousPurchase();

  const trackId: string | undefined = item?.trackId;
  const deviceId = getCookie(DEVICE_ID);
  const pastPurchaseKey = trackId || deviceId;
  if (pastPurchaseKey) {
    hasPastPurchaseOnDevice = Boolean(pastPurchaseObj[pastPurchaseKey]);
  }

  return hasPastPurchaseOnDevice;
};

export const campaignPageNameSiteSection = (pathname: string) => {
  if (!pathname.includes('contents/')) return { pageName: undefined, siteSection: undefined };
  const pathParts = pathname
    .split('contents/')[1]
    .split('/')
    .slice(2)
    .filter(el => el);
  const siteSection = pathParts[0];

  return { pageName: `${siteSection}:${pathParts.slice(1).join('/')}`, siteSection };
};

export const isMacOs = () => navigator.platform.toUpperCase().indexOf('MAC') >= 0;
