import { h } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';

import { Chat } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { cx } from '@travel/utils';

import styles from './chatBox.module.scss';

export const AWAY_TIME = 2000;
export const TO_BE_HIDDEN_TIME = 1500;
export const APPEARANCE_UPDATE_TIME = TO_BE_HIDDEN_TIME / 2;

function ChatBox() {
  const [states, setStates] = useState({ isExist: false, isShrink: false, isHidden: false });

  const timerRef = useRef<NodeJS.Timeout | number>(0);
  const initialScrollRef = useRef<number>(0);

  const onScroll = useCallback(() => {
    clearTimeout(timerRef.current as number);

    if (!states.isExist && window.pageYOffset !== initialScrollRef.current) {
      timerRef.current = setTimeout(() => {
        setStates(prev => ({ ...prev, isExist: true }));
      }, AWAY_TIME);
    }
  }, [states]);

  // effect to show the chat box after 2s of stop scrolling
  useEffect(() => {
    initialScrollRef.current = window.pageYOffset;
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  // effect to hide the chat box after 1.5s after it does exist once on screen
  useEffect(() => {
    if (states.isExist && !states.isHidden && !states.isShrink) {
      setTimeout(() => {
        setStates(prev => ({
          ...prev,
          isHidden: true,
        }));
      }, TO_BE_HIDDEN_TIME);
    }
  }, [states]);

  // effect to change the element shape during it was hidden
  // to avoid blinking effect
  useEffect(() => {
    if (states.isExist && states.isHidden && !states.isShrink) {
      setTimeout(() => {
        setStates(prev => ({
          ...prev,
          isShrink: true,
        }));
      }, APPEARANCE_UPDATE_TIME);
    }
  }, [states]);

  // effect to show the chat box in different shape after it was hidden
  useEffect(() => {
    if (states.isShrink && states.isHidden && states.isShrink) {
      setTimeout(() => {
        setStates(prev => ({
          ...prev,
          isHidden: false,
        }));
      }, APPEARANCE_UPDATE_TIME);
    }
  }, [states]);

  return (
    <div class={styles.wrapper}>
      <a
        class={cx(
          styles.linkContainer,
          states.isExist && styles.existed,
          states.isHidden && styles.hidden,
        )}
        href="https://img.travel.rakuten.co.jp/image/tr/hs/sptop/bhTpM/"
        target="_blank"
        rel="noreferrer"
        // TODO: To be replaced with Translation
        label="ヘルプ（自動回答）"
        data-testid="chatBox-link-item"
      >
        <Chat size={24} color="cilantro" />
        <span
          class={cx(styles.title, states.isShrink && styles.shrink)}
          data-testid="chatBox-link-title"
        >
          &nbsp;&nbsp;{<Translate id="Booking_List.Bottom_Floating.Chatbot.First" />}
        </span>
      </a>
    </div>
  );
}

export default ChatBox;
