import React from 'react';

import { Translate } from '@travel/translation';
import { isNotEmptyArray } from '@travel/utils';

import styles from './domesticErrorPage.module.scss';

type CommonLabelIds = {
  errorTitleLabel: string;
  followingMethodsLabel: string;
  helpPagesReferenceLabel: string;
  helpTopPageReferenceLabel: string;
  helpTopPageTextLabel: string;
  helpTopPageLinkLabel: string;
};

export type ResolutionType = {
  id: string;
  label: React.ReactNode;
};

type Props = {
  commonLabelId: CommonLabelIds;
  resolutionList: ResolutionType[];
  helpList: React.ReactNode[];
  helpTopPageLabel: React.ReactNode;
};

function DomesticErrorPage(props: Props) {
  return (
    <main className={styles.wrapper} data-testid="domesticErrorPage-wrapper">
      <img
        className={styles.errorImg}
        src="https://trvimg.r10s.jp/share/common/images/sorry_img.png"
        alt="Computer displaying text Sorry"
        loading="lazy"
        data-testid="domesticErrorPage-img"
      />
      <h2 className={styles.errorTitle}>
        <Translate id={props.commonLabelId.errorTitleLabel} />
      </h2>
      {isNotEmptyArray(props.resolutionList) && (
        <div className={styles.resolutionList}>
          <Translate id={props.commonLabelId.followingMethodsLabel} className={styles.listTitle} />
          <ul data-testid="domesticErrorPage-resolutionList-container">
            {props.resolutionList.map(resolution => {
              return (
                <li key={`${resolution.id}`} className={styles.listItem}>
                  {resolution.label}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {isNotEmptyArray(props.helpList) && (
        <>
          <Translate className={styles.faqLabel} id={props.commonLabelId.helpPagesReferenceLabel} />
          <div className={styles.faqList} data-testid="domesticErrorPage-faqList-container">
            {props.helpList}
          </div>
        </>
      )}
      <p className={styles.faqLink}>{props.helpTopPageLabel}</p>
    </main>
  );
}

export default DomesticErrorPage;
