import React from 'react';

export const lineBreak = (text: string) => {
  const splitted = text.split('\\n');
  let result: (string | React.ReactElement)[] = [];
  splitted.forEach((value, index) => {
    result.push(value);
    if (index !== splitted.length - 1) result.push(React.createElement('br'));
  });
  return React.Children.toArray(result);
};
