import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { ReservationListState } from './reducer';

import { ResponseBody } from 'ReservationsList-Types';

export const getReservationsList = (state: RootState) => state.reservationsList;

export const getIsFetching = createSelector(
  getReservationsList,
  (state: ReservationListState) => state.isFetching,
);

export const getIsFetchingMore = createSelector(
  getReservationsList,
  (state: ReservationListState) => state.isFetchingMore,
);

export const getIsQrDialogOpen = createSelector(
  getReservationsList,
  (state: ReservationListState) => state.isQrDialogOpen,
);

export const getItems = createSelector(
  getReservationsList,
  (state: ReservationListState) => state.items,
);

export const hasMoreReservations = createSelector(getItems, (items: ResponseBody) => {
  const { offset, total, limit } = items;
  return total > offset + limit;
});

export const getNextFetching = createSelector(getItems, (items: ResponseBody) => {
  const { offset, limit, total } = items;
  if (total - (offset + limit) > 10) {
    return 10;
  } else {
    return total - (offset + limit);
  }
});

export const getCurrentTab = createSelector(
  getReservationsList,
  (state: ReservationListState) => state.currentTab,
);

export const getQrCodeReservationId = createSelector(
  getReservationsList,
  (state: ReservationListState) => state.qrCodeReservationId,
);
