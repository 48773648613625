import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { CouponSearchResponseBody } from 'RakutenMobile-Types';
import {
  closeCouponSelection,
  closeFailSubmitCouponDialog,
  fetchMobileCouponListAsync,
  submitMobileCouponAsync,
} from './actions';

export const isFetchingCouponList = createReducer(false as boolean)
  .handleAction(fetchMobileCouponListAsync.request, () => true)
  .handleAction(
    [fetchMobileCouponListAsync.success, fetchMobileCouponListAsync.failure],
    () => false,
  );

export const isSubmittingCoupon = createReducer(false as boolean)
  .handleAction(submitMobileCouponAsync.request, () => true)
  .handleAction([submitMobileCouponAsync.success, submitMobileCouponAsync.failure], () => false);

export const isCouponSelectionDialogOpen = createReducer(false as boolean)
  .handleAction(fetchMobileCouponListAsync.request, () => true)
  .handleAction(
    [
      submitMobileCouponAsync.success,
      closeCouponSelection,
      submitMobileCouponAsync.failure,
      fetchMobileCouponListAsync.failure,
    ],
    () => false,
  );

export const isSubmitCouponFailedDialogOpen = createReducer(false as boolean)
  .handleAction([submitMobileCouponAsync.failure, fetchMobileCouponListAsync.failure], () => true)
  .handleAction(closeFailSubmitCouponDialog, () => false);

export const mobileErrorMessage = createReducer([] as Array<string>).handleAction(
  [submitMobileCouponAsync.failure, fetchMobileCouponListAsync.failure],
  (_state, action) => action.payload.displayMessage,
);

export const couponList = createReducer({
  reservationId: '',
  currentBookingPrice: 0,
  selectionCoupons: [],
} as CouponSearchResponseBody)
  .handleAction(fetchMobileCouponListAsync.success, (_state, action) => action.payload)
  .handleAction(
    [submitMobileCouponAsync.success, closeCouponSelection],
    () =>
      ({
        reservationId: '',
        currentBookingPrice: 0,
        selectionCoupons: [],
      } as CouponSearchResponseBody),
  );

const rakutenMobileReducer = combineReducers({
  isFetchingCouponList,
  isSubmittingCoupon,
  isCouponSelectionDialogOpen,
  isSubmitCouponFailedDialogOpen,
  couponList,
  mobileErrorMessage,
});

export default rakutenMobileReducer;
export type RakutenMobileState = ReturnType<typeof rakutenMobileReducer>;
