import cookie, { CookieSerializeOptions } from 'cookie';

export function setCookie(key: string, value: string, options?: CookieSerializeOptions) {
  if (typeof document === 'undefined' || !key) {
    return;
  }
  document.cookie = cookie.serialize(
    key,
    value,
    options || {
      path: '/',
    },
  );
}

export function setStrictCookie(key: string, value: string, options?: CookieSerializeOptions) {
  if (typeof document === 'undefined' || !key) {
    return;
  }
  document.cookie = cookie.serialize(key, value, {
    path: options?.path || '/',
    sameSite: options?.sameSite || 'strict',
    expires: options?.expires || new Date(new Date().setFullYear(new Date().getFullYear() + 5)),
  });
}

export function setSessionCookie(key: string, value: string, options?: CookieSerializeOptions) {
  if (typeof document === 'undefined' || !key) {
    return;
  }
  document.cookie = cookie.serialize(key, value, {
    path: options?.path || '/',
    sameSite: options?.sameSite || 'strict',
  });
}

export function getCookie(key: string) {
  if (typeof document === 'undefined' || !key) {
    return;
  }

  const parsedCookie = cookie.parse(document.cookie);
  return parsedCookie && (parsedCookie[key] || '');
}

const chars = '.0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
export const cookieEncode = function(data: ArrayLike<number>) {
  var s = '',
    l = data.length,
    hang = 0;
  for (var i = 0; i < l; i++) {
    var v = data[i];

    switch (i % 3) {
      case 0:
        s += chars[v >> 2];
        hang = (v & 3) << 4;
        break;
      case 1:
        s += chars[hang | (v >> 4)];
        hang = (v & 0xf) << 2;
        break;
      case 2:
        s += chars[hang | (v >> 6)];
        s += chars[v & 0x3f];
        hang = 0;
        break;
    }
  }
  if (l % 3) s += chars[hang];
  return s;
};
