import { Translate } from '@travel/translation';
/* Edge case for import-sorter and prettier */
// eslint-disable-next-line prettier/prettier
import DomesticErrorPage, {
  ResolutionType,
} from '@travel/traveler-core/components/DomesticErrorPage';
import FaqLink from '@travel/traveler-core/components/DomesticErrorPage/FaqLink';
import { useTranslation } from '@travel/traveler-core/hooks';
import { Link } from '@travel/ui';

const ERROR_PAGE_LABELS = {
  errorTitleLabel: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Title',
  followingMethodsLabel:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Navigation.Description',
  helpPagesReferenceLabel:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Description',
  helpTopPageReferenceLabel:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Help_top.Text',
  helpTopPageTextLabel:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Help_top.HyperLink_Text',
  helpTopPageLinkLabel:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Help_top.HyperLink_URL',
};

const RESOLUTION_LIST_LABELS = [
  'Booking_List.Common.Error_Message.Application_Error.Fatal.Navigation.Item1.Text',
  'Booking_List.Common.Error_Message.Application_Error.Fatal.Navigation.Item2.Text',
];

const OLD_MYPAGE_LABELS = {
  text: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Navigation.Item3.Text',
  hyperlinkUrl:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Navigation.Item3.HyperLink_URL',
  hyperlinkText:
    'Booking_List.Common.Error_Message.Application_Error.Fatal.Navigation.Item3.HyperLink_Text',
};

const HELP_LIST_LABELS = [
  {
    text: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item1.Text',
    url: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item1.URL',
  },
  {
    text: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item2.Text',
    url: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item2.URL',
  },
  {
    text: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item3.Text',
    url: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item3.URL',
  },
  {
    text: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item4.Text',
    url: 'Booking_List.Common.Error_Message.Application_Error.Fatal.Help.Item4.URL',
  },
];

function ErrorPage() {
  const helpTopPageUrl = useTranslation({ id: ERROR_PAGE_LABELS.helpTopPageLinkLabel });
  const oldMyPageUrl = useTranslation({ id: OLD_MYPAGE_LABELS.hyperlinkUrl });

  const resolutionList: ResolutionType[] = RESOLUTION_LIST_LABELS.map(resolution => {
    return {
      id: resolution,
      label: <Translate id={resolution} key={resolution} />,
    };
  });

  resolutionList.push({
    id: OLD_MYPAGE_LABELS.text,
    label: (
      <Translate
        id={OLD_MYPAGE_LABELS.text}
        key={OLD_MYPAGE_LABELS.text}
        data={{
          old_mypage: (
            <Link
              url={oldMyPageUrl}
              label={<Translate id={OLD_MYPAGE_LABELS.hyperlinkText} />}
              haveUnderline
            />
          ),
        }}
      />
    ),
  });

  return (
    <DomesticErrorPage
      commonLabelId={ERROR_PAGE_LABELS}
      resolutionList={resolutionList}
      helpList={HELP_LIST_LABELS.map(faqItem => {
        return <FaqLink key={faqItem.text} textLabelId={faqItem.text} urlLabelId={faqItem.url} />;
      })}
      helpTopPageLabel={
        <Translate
          id={ERROR_PAGE_LABELS.helpTopPageReferenceLabel}
          data={{
            help_top: (
              <Link
                url={helpTopPageUrl}
                label={<Translate id={ERROR_PAGE_LABELS.helpTopPageTextLabel} />}
                haveUnderline
              />
            ),
          }}
        />
      }
    />
  );
}

export default ErrorPage;
