import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchPitariAsync } from './actions';

import { ResponseBody } from 'Pitari-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchPitariAsync.request], () => true)
  .handleAction([fetchPitariAsync.success, fetchPitariAsync.failure], () => false);

export const item = createReducer({} as ResponseBody).handleAction(
  fetchPitariAsync.success,
  (_state, action) => action.payload,
);

const pitariReducer = combineReducers({
  isFetching,
  item,
});

export default pitariReducer;
export type PitariState = ReturnType<typeof pitariReducer>;
