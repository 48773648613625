import isEqual from 'lodash/isEqual';

export function isValidArray(array: any): array is Array<any> {
  return Array.isArray(array);
}

export function isNotEmptyArray(array: any): array is Array<any> {
  return Array.isArray(array) && !!array.length;
}

export function isEmptyArray(array: unknown): array is undefined | [] {
  return !Array.isArray(array) || (Array.isArray(array) && array.length === 0);
}

export const isNotLast = (arr: Array<any>, index: number): boolean => index !== arr.length - 1;

/** Confirm whether the value in array is unique or not. */
export function isUniqueInArray(array: Array<any>, targetIndex: number) {
  const value = array[targetIndex];
  return !array.some((v: any, index: number) => index !== targetIndex && isEqual(v, value));
}
