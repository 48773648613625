import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { getCookie } from '@travel/utils';

import { ONE_TRUST_CONSENT, ONE_TRUST_TRACKING_ON_VAL, RAN_COOKIE } from '../constants';
import { ADJUST_DEEP_LINK, ADJUST_PROTOCOL, ADJUST_REFERRER } from '../constants/common';
import { RanData } from '../store/ranStore/types';

dayjs.extend(utc);

/**
 * Parses query values of adult and childrenAges to one number value
 * @param adults <String | undefined> number of adults from query
 * @param childrenAges <String | undefined> childrenAges from query
 */
export const numOfGuestParser = (adults?: string, childrenAges?: string) =>
  parseInt(adults || '0') + (childrenAges?.split(',')?.length || 0);

export const DATE_FORMAT = 'YYYY-MM-DD';

export function JSONparse<T>(JSONstring: string | null | undefined): T | void {
  if (!JSONstring) return;
  try {
    return JSON.parse(JSONstring);
  } catch (error) {
    return;
  }
}

export const addQueryDataToUrl = (
  url: string,
  data?: { queryString?: string; queryObject?: Record<string, string> },
) => {
  if (!data && (!window || !window.location)) {
    return url;
  }
  const { queryString, queryObject } = data || {};

  try {
    const urlObj = new URL(url);
    const newParams = new URLSearchParams(queryString || window.location.search);
    for (const [key, value] of newParams.entries()) {
      const current = urlObj.searchParams.get(key);
      if (current && current !== value) {
        urlObj.searchParams.set(key, value);
      } else {
        urlObj.searchParams.set(key, value);
      }
    }
    // remove l-id from url
    urlObj.searchParams.delete('l-id');

    if (queryObject) {
      for (const key in queryObject) {
        urlObj.searchParams.set(key, `${queryObject[key]}`);
      }
    }

    return urlObj.toString();
  } catch (error) {
    console.warn('addRanToUrlClient', error);
    return url;
  }
};

/**
 * RAKUTEN AFFILIATE NETWORK (RAN) add the info to URL for deepLink spec
 * from query string or cookie or store
 * @param url (string) the url you want to add the querystring to
 * @param queryString (string) the new queryString you want to add
 * @returns a new Url with the queryString
 */
export const addRanToUrlClient = (
  url: string,
  data?: { queryString?: string; ranData?: RanData },
) => {
  if (!data && (!window || !window.location)) {
    return url;
  }
  const { queryString, ranData } = data || {};

  try {
    const ranDataToAdd: RanData | null =
      JSONparse<RanData>(getCookie(RAN_COOKIE)) || ranData || null;

    return addQueryDataToUrl(url, {
      queryString,
      queryObject: (ranDataToAdd as Record<string, string>) || undefined,
    });
  } catch (error) {
    console.warn('addRanToUrlClient', error);
    return url;
  }
};

export const getRanDataQueryString = (ranData?: RanData | null) => {
  const ranDataToAdd: RanData | null = JSONparse<RanData>(getCookie(RAN_COOKIE)) || ranData || null;
  if (ranDataToAdd) {
    return new URLSearchParams(ranDataToAdd as Record<string, string>).toString();
  }

  return '';
};

export const addRanDataToAppUrl = (url: string, isIOS: boolean, ranData?: RanData) => {
  const ranDataToAdd: RanData | null = JSONparse<RanData>(getCookie(RAN_COOKIE)) || ranData || null;
  const ranDataQS = getRanDataQueryString(ranData);

  if (ranDataQS && isIOS) {
    return `${url}?${ADJUST_DEEP_LINK}=${encodeURIComponent(
      `${ADJUST_PROTOCOL}?${ranDataQS}`,
    )}&install_callback=${encodeURIComponent(
      `https://mobile.linksynergy.com/data/v1/adjust/{os_name}/{app_name}?en=install&ct={click_time}&mid=50146&cid=${ranDataToAdd?.ranSiteID}&tt={installed_at}`,
    )}&event_callback_7jbapw=${encodeURIComponent(
      `https://mobile.linksynergy.com/data/v1/adjust/{os_name}/{app_name}?en=purchase&ct={click_time}&mid=50146&cid=${ranDataToAdd?.ranSiteID}&tt={created_at}&revenue={revenue_float}&c={currency}&partner_param={partner_parameters}`,
    )}`;
  } else if (ranDataQS) {
    return `${url}?${ADJUST_REFERRER}=${encodeURIComponent(
      ranDataQS,
    )}&install_callback=${encodeURIComponent(
      `https://mobile.linksynergy.com/data/v1/adjust/{os_name}/{app_name}?en=install&ct={click_time}&mid=50146&cid=${ranDataToAdd?.ranSiteID}&tt={installed_at}`,
    )}&event_callback_7jbapw=${encodeURIComponent(
      `https://mobile.linksynergy.com/data/v1/adjust/{os_name}/{app_name}?en=purchase&ct={click_time}&mid=50146&cid=${ranDataToAdd?.ranSiteID}&tt={created_at}&revenue={revenue_float}&c={currency}&partner_param={partner_parameters}`,
    )}`;
  }

  return url;
};

export const addRanDataToAppQRUrl = (url: string, isIOS: boolean, ranData?: RanData) => {
  const ranDataToAdd: RanData | null = JSONparse<RanData>(getCookie(RAN_COOKIE)) || ranData || null;
  const ranDataQS = getRanDataQueryString(ranDataToAdd);

  if (ranDataQS && isIOS) {
    return `${url}?${ADJUST_DEEP_LINK}=${encodeURIComponent(`${ADJUST_PROTOCOL}?${ranDataQS}`)}`;
  } else if (ranDataQS) {
    return `${url}?${ADJUST_REFERRER}=${encodeURIComponent(ranDataQS)}`;
  }

  return url;
};

export const ranDataToSendForConversation = (ranData?: RanData) => {
  const ranDataToSend: RanData | null =
    JSONparse<RanData>(getCookie(RAN_COOKIE)) || ranData || null;
  const consentCookie = getCookie(ONE_TRUST_CONSENT);
  const isTrackingAllowed = consentCookie?.includes(ONE_TRUST_TRACKING_ON_VAL);

  if (!ranDataToSend || !isTrackingAllowed) {
    return null;
  }

  const timeEntered =
    ranDataToSend.ranDate &&
    dayjs(ranDataToSend.ranDate)
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]');

  return {
    ...(ranDataToSend.ranSiteID ? { siteId: ranDataToSend.ranSiteID } : {}),
    ...(timeEntered ? { timeEntered } : {}),
    ...(ranDataToSend.scid ? { scid: ranDataToSend.scid } : {}),
  };
};

export const isValidRoomSearchCondition = (value: string | number | unknown) => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return false;
  }
  return /^\d+$/.test(String(value));
};
