import { Store } from 'typesafe-actions';

import loggerGenerator from '@travel/logger';
import { noticeError } from '@travel/tracker';

import { setCommonError } from 'store/commonError/actions';

const logger = loggerGenerator();

const setCommonErrorHandler = (store: Store) => ({
  status,
  url,
  response,
}: {
  status: number;
  url: string;
  response?: Response;
}) => {
  // const state = store.getState();
  // console.error('status', status, url, state.userAgent?.userAgent?.source || 'no-user-agent');
  store.dispatch(setCommonError({ message: 'test error message', httpStatusCode: status }));
  response
    ?.json()
    .then(json => {
      if (/^50/.test(String(response.status))) {
        noticeError('API 50x Error', {
          url: response.url,
          res: response,
          json,
        });
      } else if (/^40/.test(String(response.status))) {
        noticeError('API 40x Error', {
          url: response.url,
          res: response,
          json,
        });
      } else {
        noticeError(`${status} error`, {
          url: response.url,
          res: response,
          json,
        });
      }
    })
    .catch(e => {
      logger.error(`Common error ${e}`);
      noticeError(`${status} error`, {
        url,
        res: response,
      });
    });
};

export default setCommonErrorHandler;
