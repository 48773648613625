import { FeaturesServiceAna, FeaturesServiceJal, FeaturesServiceJr } from '@travel/icons/features';

export enum ReservationStatusColor {
  UPCOMING = 'green',
  CANCELED = 'red',
  PAST = 'yellow',
  NOSHOW = 'red',
  MODIFIED = 'green',
  REQUESTED = 'green',
}

export enum Period {
  PAST_6_MONTH = 'PAST_6_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  '2_YEARS_AGO' = '2_YEARS_AGO',
  '3_YEARS_AGO' = '3_YEARS_AGO',
}

export enum ServiceType {
  DOMESTIC = 'DOMESTIC',
  DAY_USE = 'DAY_USE',
  ANA = 'ANA',
  JAL = 'JAL',
  JR = 'JR',
  RENTAL_CAR = 'RENTAL_CAR',
  BUS = 'BUS',
  OVERSEAS_HOTEL = 'OVERSEAS_HOTEL',
  OVERSEAS_AIR = 'OVERSEAS_AIR',
  OVERSEAS_DP = 'OVERSEAS_DP',
  // for filtering
  DOMESTIC_DP = 'DOMESTIC_DP',
  // fallback case
  EMPTY = '',
}

export enum Status {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  CANCELED = 'CANCELED',
  NOSHOW = 'NOSHOW',
  REQUESTED = 'REQUESTED',
  MODIFIED = 'MODIFIED',
}

export enum Sort {
  BOOKING_DATE_ASC = 'BOOKING_DATE_ASC',
  BOOKING_DATE_DESC = 'BOOKING_DATE_DESC',
  CHECKIN_DATE_ASC = 'CHECKIN_DATE_ASC',
  CHECKIN_DATE_DESC = 'CHECKIN_DATE_DESC',
  RESERVATION_MODIFIED_DATE_ASC = 'RESERVATION_MODIFIED_DATE_ASC',
  RESERVATION_MODIFIED_DATE_DESC = 'RESERVATION_MODIFIED_DATE_DESC',
}

export enum PaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  LOCAL = 'LOCAL',
  CORPORATE_CHARGE = 'CORPORATE_CHARGE',
  ALLIANCE = 'ALLIANCE',
}

export const COMPANY_LOGO = {
  [ServiceType.JAL]: FeaturesServiceJal,
  [ServiceType.ANA]: FeaturesServiceAna,
  [ServiceType.JR]: FeaturesServiceJr,
};

export enum CouponStatus {
  APPLICABLE = 'APPLICABLE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  ALREADY_APPLIED = 'ALREADY_APPLIED',
  DISABLE = 'DISABLE',
}
