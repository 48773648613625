import loadable from '@loadable/component';
import { h } from 'preact';
import UniversalRouter from 'universal-router';

import { parseQueryString } from '@travel/utils';

import reservationListResolver from 'pages/ReservationList/resolver';

import paths from 'constants/paths';
import App from 'core/app';
import { universalRouterProps } from './types';

export default (props: universalRouterProps) => {
  const isServer = props.isServer || false;

  const routes = [
    {
      path: '/',
      async action(context: any) {
        let children;

        try {
          children = await context.next();
        } catch (e) {
          console.error(e);
          children = null;
        }

        return (
          <App {...context} key="app">
            {children}
          </App>
        );
      },
      children: [
        {
          path: paths.top.path,
          async action(context: any) {
            const ReservationList = loadable(() =>
              import(/* webpackChunkName: "pages-ReservationList" */ 'pages/ReservationList'),
            );

            if (isServer) {
              await reservationListResolver(props, context);
            } else if (!context.skipResolver) {
              reservationListResolver(props, context);
            }

            return <ReservationList {...context} />;
          },
        },
        {
          // also resolve paths.notFound.path
          path: '(.*)',
          async action() {
            const ErrorPage = loadable(() =>
              import(/* webpackChunkName: "pages-ErrorPage" */ 'pages/ErrorPage'),
            );

            props.res?.status(400);
            return <ErrorPage />;
          },
        },
      ],
    },
  ];
  return new UniversalRouter(routes, {
    resolveRoute(context, params) {
      if (typeof context.route.action === 'function') {
        context.query = {};
        if (context.search) {
          // when redirect from omni with existing filter,
          // there will be ? added from the redirection
          const searchRegex = new RegExp('\\?$');
          const currentSearch = context.search;
          context.query = parseQueryString(
            searchRegex.test(currentSearch)
              ? currentSearch.substring(0, currentSearch.length - 1)
              : currentSearch,
          );
        }

        return context.route.action(context, params);
      }
      return undefined;
    },
  });
};
