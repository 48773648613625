import { useSelector } from 'react-redux';

import { getDictionary } from '@travel/i18n';
import { getTranslation } from '@travel/translation/src/utils';
import { TranslateProps } from '@travel/translation/src/utils/types';

export const useTranslation = (props: TranslateProps) => {
  const dictionary = useSelector(getDictionary);

  return getTranslation(props, dictionary)?.join('') || '';
};

export const useTranslationArr = (props: TranslateProps) => {
  const dictionary = useSelector(getDictionary);

  return getTranslation(props, dictionary) || '';
};

export const useGetTranslation = () => {
  const dictionary = useSelector(getDictionary);

  return (props: TranslateProps) => getTranslation(props, dictionary)?.join('') || '';
};
