import React, { ReactNode } from 'react';

import { TRACKER_ENABLED } from '../constants';

type Props = {
  device: string;
  type: string;
  ratAccountId: number;
  ratServiceId: number;
  children?: ReactNode;
};

function ThirdPartyTagsScripts(props: Props) {
  const { children, ratAccountId, ratServiceId } = props;
  const script = 'https://r.r10s.jp/com/rat/js/rat-main.js';

  return TRACKER_ENABLED ? (
    <div style={{ display: 'none' }}>
      <form className="ratForm" name="ratForm" id="ratForm">
        <input type="hidden" name="rat" id="ratSinglePageApplicationLoad" value="true" />
        <input type="hidden" name="rat" id="ratAccountId" value={ratAccountId} />
        <input type="hidden" name="rat" id="ratServiceId" value={ratServiceId} />
        {children}
      </form>
      <script type="text/javascript" src={script} defer={true} />
    </div>
  ) : null;
}

ThirdPartyTagsScripts.defaultProps = {
  device: 'pc',
  type: '',
};

export default ThirdPartyTagsScripts;
