import React, { useEffect, useLayoutEffect, useRef, useState, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Action, History, Location } from 'history';

import { getLanguage, getMarket } from '@travel/i18n';

import { locationChangeBegin, locationChangeEnd } from 'store/__router/actions';

type Props = {
  router: { resolve: (location: Location) => Promise<ReactNode> };
  history: History;
  children: ReactNode;
};

function Router(props: Props) {
  const { router, history, children } = props;
  const dispatch = useDispatch();
  const currentLocation = useRef<Location>();
  const currentAction = useRef<Action>();
  const [Component, setComponent] = useState(children);

  const market = useSelector(getMarket);
  const language = useSelector(getLanguage);

  useEffect(() => {
    let unlisten = history.listen((location, action) => {
      dispatch(locationChangeBegin());
      currentLocation.current = location;
      currentAction.current = action;

      router.resolve(location).then((NextComponent: ReactNode) => {
        // XXX: if location doesn't match current location, it means a user triggered location change
        // before the previous one has been resolved. In this situation we skip the previous location change.
        if (location === currentLocation.current && action === currentAction.current) {
          dispatch(locationChangeEnd({ location, action }));
          setComponent(NextComponent);
        }
      });
    });

    return () => unlisten();
  }, [dispatch, history, language, market, router]);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      // We need to stop browser's default scroll restoration, because browser will restore the scroll position right
      // after history.push (or pop/...), but sometimes we don't render the next page immediately. This will cause
      // unexpected flash on previous page
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  // XXX: This is a hacky solution. I'm using useLayoutEffect here is just to make sure that if any child component
  // wants to overwrite the scroll position, it can use useEffect because useEffect will be excuted after useLayoutEffect.
  // We need to find another solution and change it to use useEffect if this solution brings any performance issue
  useLayoutEffect(() => {
    if (currentAction.current !== 'REPLACE') {
      window.scrollTo(0, 0);
    }
  }, [Component]);

  return <>{Component}</>;
}

export default Router;
