import { KeyboardEvent } from 'react';

enum KeyCodes {
  ENTER = 13,
  SPACE_BAR = 32,
}

export function ariaOnKeyDown<HTMLElementType = HTMLDivElement>(
  callBack: (event: KeyboardEvent<HTMLElementType>) => void,
  expectedKeyCodes?: Array<number>,
) {
  return (event: KeyboardEvent<HTMLElementType>) => {
    if (
      event.keyCode === KeyCodes.ENTER ||
      event.keyCode === KeyCodes.SPACE_BAR ||
      (expectedKeyCodes && expectedKeyCodes.indexOf(event.keyCode) > -1)
    ) {
      event.preventDefault();
      callBack(event);
    }
  };
}
