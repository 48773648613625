import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  closeCouponSelection,
  closeFailSubmitCouponDialog,
  fetchCouponListAsync,
  submitCouponAsync,
} from './actions';

import { CouponSearchResponseBody } from 'FurusatoNozei-Types';

export const isFetchingCouponList = createReducer(false as boolean)
  .handleAction(fetchCouponListAsync.request, () => true)
  .handleAction([fetchCouponListAsync.success, fetchCouponListAsync.failure], () => false);

export const isSubmittingCoupon = createReducer(false as boolean)
  .handleAction(submitCouponAsync.request, () => true)
  .handleAction([submitCouponAsync.success, submitCouponAsync.failure], () => false);

export const isCouponSelectionDialogOpen = createReducer(false as boolean)
  .handleAction(fetchCouponListAsync.request, () => true)
  .handleAction(
    [
      submitCouponAsync.success,
      closeCouponSelection,
      submitCouponAsync.failure,
      fetchCouponListAsync.failure,
    ],
    () => false,
  );

export const isSubmitCouponFailedDialogOpen = createReducer(false as boolean)
  .handleAction([submitCouponAsync.failure, fetchCouponListAsync.failure], () => true)
  .handleAction(closeFailSubmitCouponDialog, () => false);

export const furusatoErrorMessage = createReducer([] as Array<string>).handleAction(
  [submitCouponAsync.failure, fetchCouponListAsync.failure],
  (_state, action) => action.payload.displayMessage,
);

export const couponList = createReducer({
  reservationId: '',
  currentBookingPrice: 0,
  selectionCoupons: [],
} as CouponSearchResponseBody)
  .handleAction(fetchCouponListAsync.success, (_state, action) => action.payload)
  .handleAction(
    [submitCouponAsync.success, closeCouponSelection],
    () =>
      ({
        reservationId: '',
        currentBookingPrice: 0,
        selectionCoupons: [],
      } as CouponSearchResponseBody),
  );

const furusatoNozeiReducer = combineReducers({
  isFetchingCouponList,
  isSubmittingCoupon,
  isCouponSelectionDialogOpen,
  isSubmitCouponFailedDialogOpen,
  couponList,
  furusatoErrorMessage,
});

export default furusatoNozeiReducer;
export type FurusatoNozeiState = ReturnType<typeof furusatoNozeiReducer>;
