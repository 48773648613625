import { encode } from 'js-base64';
import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import { getReservationsListAPI } from './api';

import generateLogger from '@travel/logger';

import { fetchAuthCodeAsync } from 'store/authCode/actions';

import { AuthCodeError } from 'AuthCode-Types';
import { Status } from 'constants/reservations';
import { RequestBody } from 'ReservationsList-Types';

const logger = generateLogger();
export const fetchReservationsListAsync = createAsyncAction(
  'FETCH_RESERVATION_LIST_REQUEST',
  'FETCH_RESERVATION_LIST_SUCCESS',
  'FETCH_RESERVATION_LIST_FAILURE',
)<undefined, any, any>();

export const fetchMoreReservationListAsync = createAsyncAction(
  'FETCH_MORE_RESERVATION_LIST_REQUEST',
  'FETCH_MORE_RESERVATION_LIST_SUCCESS',
  'FETCH_MORE_RESERVATION_LIST_FAILURE',
)<undefined, any, any>();

export const setCurrentTab = createStandardAction('SET_CURRENT_TAB')<Status>();
export const openQrDialog = createStandardAction('OPEN_QR_DIALOG')<string>();
export const closeQrDialog = createStandardAction('CLOSE_QR_DIALOG')();

export const fetchReservationList = (params: RequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchReservationsListAsync.request());
  const { authCode, _httpRequest } = getState();
  const { token } = authCode.item;
  const { normalizedCookies } = _httpRequest;
  // do not fetch if there is no token
  if (!token) return;

  try {
    const response = await getReservationsListAPI(apiClient, {
      ...params,
      fIdSession: encode(normalizedCookies.f_id_session.value),
    });
    dispatch(setCurrentTab(params.reservationList));
    dispatch(fetchReservationsListAsync.success(response));
  } catch (error) {
    if (error.status === 401) {
      dispatch(fetchAuthCodeAsync.failure(error as AuthCodeError));
    } else {
      logger.error(error);
      dispatch(fetchReservationsListAsync.failure(error));
    }
  }
};

export const fetchMoreReservation = (params: RequestBody): AppThunk => async (
  dispatch,
  getState,
  { apiClient },
) => {
  dispatch(fetchMoreReservationListAsync.request());
  const { reservationsList, _httpRequest } = getState();
  const { normalizedCookies } = _httpRequest;
  const { offset, limit } = reservationsList.items;

  const req = {
    ...params,
    offset: limit + offset,
    limit: limit,
    fIdSession: encode(normalizedCookies.f_id_session.value),
  };
  try {
    const response = await getReservationsListAPI(apiClient, req);
    dispatch(fetchMoreReservationListAsync.success(response));
  } catch (error) {
    if (error.status === 401) {
      dispatch(fetchAuthCodeAsync.failure(error as AuthCodeError));
    } else {
      logger.error(error);
      dispatch(fetchMoreReservationListAsync.failure(error));
    }
  }
};
