import { ApiClient } from '@travel/api-client';
import env from '@travel/env';

import { ResponseBody } from 'Pitari-Types';

export const getPitari = async (http: ApiClient): Promise<ResponseBody> => {
  const url =
    env('UNIVERSAL_API_MOCK_SERVER') === 'true'
      ? env('UNIVERSAL_MOCK_PITARI_API_SERVER_URL')
      : env('UNIVERSAL_PITARI_API_SERVER_URL');

  return http.get(url as string, {
    ignoreCommonErrorHandler: true,
  });
};
