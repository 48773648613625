import Decimal from 'decimal.js-light';

import currencyConfig from '../config/currencyConfig';
import languageConfigMapping from '../config/languageConfig';
import * as _marketConfig from '../config/marketConfig';

import { CountryCode } from 'libphonenumber-js';
import {
  DateFormat,
  DecimalValue,
  LanguageConfig,
  MarketConfig,
  NegativePatterns,
  NumberFormatSeparators,
  PositivePatterns,
} from '../config/types';

const marketConfig = _marketConfig as MarketConfig;
const languageConfig: LanguageConfig = languageConfigMapping;

/**
 * @returns List of supported markets represented as Country ISO 3166-1 alpha-3 code.
 */
export function getSupportedMarkets() {
  return [
    // Supported markets in P1
    'AUS',
    'CAN',
    'CHN',
    'FRA',
    'GBR',
    'HKG',
    'IDN',
    'JPN',
    'KOR',
    'MAC',
    'SGP',
    'THA',
    'TWN',
    'USA',
    // Supported markets in P2
    'DEU',
    'ESP',
    'IND',
    'ITA',
    'MYS',
    'PHL',
    'VNM',
  ];
}

/**
 * Retrieves measurement system based on provided market
 *
 * @param market Market as ISO 3166-1 alpha-3 code
 * @returns Measurement system METRIC/IMPERIAL
 */
export function getMeasurementSystem(market: string) {
  return marketConfig[market]?.measurementSystem ?? marketConfig._DEFAULT.measurementSystem;
}

/**
 * Retrieves popular destinations based on provided market
 *
 * @param market Market as ISO 3166-1 alpha-3 code
 * @returns Popular destinations object with locale info
 */
export function getPopularDestinationsWithLocaleInfo(market: string) {
  return marketConfig[market]?.popularDestinations ?? marketConfig._DEFAULT.popularDestinations;
}

/**
 * Retrieves name format based on provided market
 *
 * @param market Market as ISO 3166-1 alpha-3 code
 * @returns Full name format pattern
 */
export function getNameFormat(market: string) {
  return marketConfig[market]?.nameFormat ?? marketConfig._DEFAULT.nameFormat;
}

/**
 * Retrieves address format based on provided market
 *
 * @param market Market as ISO 3166-1 alpha-3 code
 * @returns Full address format pattern
 */
export function getAddressFormat(market: string) {
  return marketConfig[market]?.addressFormat ?? marketConfig._DEFAULT.addressFormat;
}

/**
 * Retrieves number format separators based on provided market
 *
 * @param market Market as ISO 3166-1 alpha-3 code
 * @returns Pattern for number thousands and decimals separators
 */
export function getNumberFormatSeparators(market: string) {
  return (
    marketConfig[market]?.numberFormatSeparators ?? marketConfig._DEFAULT.numberFormatSeparators
  );
}

/**
 * Retrieves first day of the week based on provided market
 *
 * @param market Market as ISO 3166-1 alpha-3 code
 * @returns First day of the week as MONDAY=true, SUNDAY=false
 */
export function getFirstDayOfWeek(market: string) {
  return marketConfig[market]?.firstDayOfTheWeek ?? marketConfig._DEFAULT.firstDayOfTheWeek;
}

/**
 * Retrieves date and time formats based on combination of market and language
 *
 * @param language Language as combination of ISO 639 (language) and ISO 3166-1 (region)
 * @param market Market as ISO 3166-1 alpha-3
 * @returns Object of date and time formats as string. See moment.js formats
 */
export function getCustomDateTimeFormat(language: string, market: string): DateFormat {
  const defaultDateConfig = marketConfig._DEFAULT.dateFormat;
  const marketDateConfig = marketConfig[market]?.dateFormat || defaultDateConfig;

  if (!marketDateConfig) {
    return defaultDateConfig;
  }

  // custom locale characters for year/month/day
  const dateLocaleCharacters = languageConfig[language]?.dateLocaleCharacters;

  const getMergedDateConfig = () => {
    if (dateLocaleCharacters) {
      return {
        ...getNewDateFormats(),
        ...marketDateConfig,
      };
    }
    return marketDateConfig;
  };

  const getNewDateFormats = () => {
    return {
      ...getNewDatesExceptShortFormats(),
      ...getShortFormat(),
    };
  };

  const getNewDatesExceptShortFormats = () => {
    const defaultLocales = dateLocaleCharacters?.default;
    if (defaultLocales) {
      const { year, month, day } = defaultLocales;
      return {
        MD: `M${month || 'MM '}D${day || 'D'}`,
        YM: `YYYY${year || '-'}M${month || ''}`,
        LL: `YYYY${year || '-'}M${month || '-'}D${day || ''}`,
        LLL: `YYYY${year || ' '}M${month || ' '}D${day || ''}`,
        Y: `YYYY${year || ''}`,
      };
    }
    return {};
  };

  const getShortFormat = () => {
    const shortLocales = dateLocaleCharacters?.short;
    if (shortLocales) {
      return {
        L: `YYYY${shortLocales.year}M${shortLocales.month}D${shortLocales.day}`,
      };
    }
    return {};
  };

  const mergeWithDefaultConfig = (
    defaultDateConfig: DateFormat,
    mergedDateConfig: Partial<DateFormat>,
  ) => {
    return {
      ...defaultDateConfig,
      ...mergedDateConfig,
    };
  };

  // breaks if there are different formats added in the future or if LTS is different from LT
  // "h" implies 12H time format
  // see: https://day.js.org/docs/en/display/format#list-of-all-available-formats
  const adjustFor12HFormat = (mergedWithDefaultDateConfig: DateFormat) => {
    const is12HFormat = marketDateConfig.LT?.includes('h');
    const isPrefixMeridiem =
      languageConfig[language]?.prefixMeridiem ?? languageConfig._DEFAULT.prefixMeridiem;

    if (is12HFormat && isPrefixMeridiem) {
      return {
        ...mergedWithDefaultDateConfig,
        LT: mergedWithDefaultDateConfig.LTwithPrefixMeridiem,
        LTS: mergedWithDefaultDateConfig.LTSwithPrefixMeridiem,
      };
    }

    return mergedWithDefaultDateConfig;
  };

  // 1. generate custom locale date formats if required and use them
  // if formats don't exist for a market
  const mergedDateConfig = getMergedDateConfig();

  // 2. take missing fields from default config
  const mergedWithDefaultDateConfig = mergeWithDefaultConfig(defaultDateConfig, mergedDateConfig);

  // 3. replace default market time formats with prefix meridiem version
  return adjustFor12HFormat(mergedWithDefaultDateConfig);
}

/**
 * Returns object with a `meridiem` function that determines the meridiem (ante/post) based on the hour
 * Follows format used for dayjs.updateLocale
 *
 * @param meridiem - Object containing the meridiem strings for ante and post
 * @returns Object with `meridiem` function.
 */
function getMeridiemFnObj(meridiem: { ante: string; post: string }) {
  return {
    meridiem: (hour: number) => (hour < 12 ? meridiem.ante : meridiem.post),
  };
}

/**
 * Retrieves locale meridiems based on the language config
 * along with specific meridiems for different markets
 *
 * @returns Object of locale meridiems
 */
export function getLocaleMeridiems() {
  type MeridiemFunctionObj = {
    meridiem: (hour: number) => string;
  };

  type LocaleMeridiems = {
    [language: string]: MeridiemFunctionObj;
  };

  const localeMeridiems: LocaleMeridiems = {};
  Object.entries(languageConfig).forEach(([language, config]) => {
    const meridiem = config.localeMeridiem;

    if (meridiem) {
      localeMeridiems[language] = getMeridiemFnObj(meridiem);
    }
  });

  return localeMeridiems;
}

/**
 * Retrieves currency pattern based on flags
 *
 * @param currency Currency as ISO 4217 code
 * @param isJPYAlternative Flag to define if alternate format for JPY is to be used
 * @param isGlobalCurrency Flag to define whether the sign will be for global case or not
 * @returns Pattern for negative and positive amounts of money
 */
export function getCurrencyPatternWithFlag(
  currency: string,
  isJPYAlternative: boolean,
  isGlobalCurrency?: boolean,
) {
  return isGlobalCurrency
    ? getCurrencyPattern(currency)
    : getDomesticCurrencyPattern(currency, isJPYAlternative);
}

/**
 * Retrieves domestic currency pattern
 *
 * @param currency Currency as ISO 4217 code
 * @param isJPYAlternative Flag to define if alternate format for JPY is to be used
 * @returns Pattern for negative and positive amounts of money
 */
export function getDomesticCurrencyPattern(currency: string, isJPYAlternative: boolean) {
  // hasn't been put in the currency config as this is exclusive to JPY
  if (currency === 'JPY') {
    if (isJPYAlternative) {
      return {
        positive: PositivePatterns.AMOUNT_SPACE_SIGN,
        negative: NegativePatterns.MINUS_AMOUNT_SPACE_SIGN,
      };
    } else {
      return {
        positive: PositivePatterns.AMOUNT_SIGN,
        negative: NegativePatterns.MINUS_AMOUNT_SIGN,
      };
    }
  } else {
    return getCurrencyPattern(currency);
  }
}

/**
 * Checks if currency uses 0 decimal places
 *
 * @param currency Currency as ISO 4217 code
 * @returns true or false based on currency using 0 decimal places
 */
export function isCurrencyInteger(currency: string) {
  return currencyConfig[currency]?.decimalPlaces === 0;
}

/**
 * Retrieves pattern to be used in rendering a currency
 *
 * @param currency Currency as ISO 4217 code
 * @returns Pattern for negative and positive amounts of money
 */
export function getCurrencyPattern(currency: string) {
  return currencyConfig[currency]?.pattern || currencyConfig.default.pattern;
}

/**
 * Retrieves domestic currency sign
 *
 * @param currency Currency as ISO 4217 code
 * @returns Currency sign
 */
export function getDomesticCurrencySign(currency: string) {
  return currencyConfig[currency]?.sign?.domestic ?? currency;
}

/**
 * Retrieves global currency sign
 *
 * @param currency Currency as ISO 4217 code
 * @returns Currency sign
 */
export function getGlobalCurrencySign(currency: string) {
  return currencyConfig[currency]?.sign?.global ?? getDomesticCurrencySign(currency);
}

/**
 * Retrieves domestic currency pattern
 *
 * @param currency Currency as ISO 4217 code
 * @param isGlobalCurrency Flag to define whether the sign will be for global case or not
 * @returns Currency sign
 */
export function getCurrencySign(currency: string, isGlobalCurrency?: boolean) {
  return isGlobalCurrency ? getGlobalCurrencySign(currency) : getDomesticCurrencySign(currency);
}

/**
 * Retrieves decimal places to be used in a currency
 *
 * @param currency Currency as ISO 4217 code
 * @returns Number of decimal places
 */
export function getCurrencyDecimals(currency: string) {
  return currencyConfig[currency]?.decimalPlaces ?? currencyConfig.default.decimalPlaces;
}

/**
 * Retrieves number format separators based on provided currency
 *
 * @param currency Currency as ISO 4217 code
 * @returns Pattern for number thousands and decimals separators
 */
export function getCurrencyNumberFormatSeparators(currency: string) {
  return currencyConfig[currency]?.numberFormat ?? currencyConfig.default.numberFormat;
}

/**
 * @param alpha2 Country ISO 3166-1 alpha-2 code.
 * @returns Country ISO 3166-1 alpha-3 code.
 * @url https://en.wikipedia.org/wiki/ISO_3166-1
 */
export function getCountryCodeIsoAlpha3FromIsoAlpha2(alpha2: CountryCode) {
  const countryCodeIsoAlpha3FromIsoAlpha2: { [key: string]: string } = {
    AD: 'AND',
    AE: 'ARE',
    AF: 'AFG',
    AG: 'ATG',
    AI: 'AIA',
    AL: 'ALB',
    AM: 'ARM',
    AO: 'AGO',
    // AQ: 'ATA',
    AR: 'ARG',
    AS: 'ASM',
    AT: 'AUT',
    AU: 'AUS',
    AW: 'ABW',
    AX: 'ALA',
    AZ: 'AZE',
    BA: 'BIH',
    BB: 'BRB',
    BD: 'BGD',
    BE: 'BEL',
    BF: 'BFA',
    BG: 'BGR',
    BH: 'BHR',
    BI: 'BDI',
    BJ: 'BEN',
    BL: 'BLM',
    BM: 'BMU',
    BN: 'BRN',
    BO: 'BOL',
    BQ: 'BES',
    BR: 'BRA',
    BS: 'BHS',
    BT: 'BTN',
    // BV: 'BVT',
    BW: 'BWA',
    BY: 'BLR',
    BZ: 'BLZ',
    CA: 'CAN',
    CC: 'CCK',
    CD: 'COD',
    CF: 'CAF',
    CG: 'COG',
    CH: 'CHE',
    CI: 'CIV',
    CK: 'COK',
    CL: 'CHL',
    CM: 'CMR',
    CN: 'CHN',
    CO: 'COL',
    CR: 'CRI',
    CU: 'CUB',
    CV: 'CPV',
    CW: 'CUW',
    CX: 'CXR',
    CY: 'CYP',
    CZ: 'CZE',
    DE: 'DEU',
    DJ: 'DJI',
    DK: 'DNK',
    DM: 'DMA',
    DO: 'DOM',
    DZ: 'DZA',
    EC: 'ECU',
    EE: 'EST',
    EG: 'EGY',
    EH: 'ESH',
    ER: 'ERI',
    ES: 'ESP',
    ET: 'ETH',
    FI: 'FIN',
    FJ: 'FJI',
    FK: 'FLK',
    FM: 'FSM',
    FO: 'FRO',
    FR: 'FRA',
    GA: 'GAB',
    GB: 'GBR',
    GD: 'GRD',
    GE: 'GEO',
    GF: 'GUF',
    GG: 'GGY',
    GH: 'GHA',
    GI: 'GIB',
    GL: 'GRL',
    GM: 'GMB',
    GN: 'GIN',
    GP: 'GLP',
    GQ: 'GNQ',
    GR: 'GRC',
    // GS: 'SGS',
    GT: 'GTM',
    GU: 'GUM',
    GW: 'GNB',
    GY: 'GUY',
    HK: 'HKG',
    // HM: 'HMD',
    HN: 'HND',
    HR: 'HRV',
    HT: 'HTI',
    HU: 'HUN',
    ID: 'IDN',
    IE: 'IRL',
    IL: 'ISR',
    IM: 'IMN',
    IN: 'IND',
    IO: 'IOT',
    IQ: 'IRQ',
    IR: 'IRN',
    IS: 'ISL',
    IT: 'ITA',
    JE: 'JEY',
    JM: 'JAM',
    JO: 'JOR',
    JP: 'JPN',
    KE: 'KEN',
    KG: 'KGZ',
    KH: 'KHM',
    KI: 'KIR',
    KM: 'COM',
    KN: 'KNA',
    KP: 'PRK',
    KR: 'KOR',
    KW: 'KWT',
    KY: 'CYM',
    KZ: 'KAZ',
    LA: 'LAO',
    LB: 'LBN',
    LC: 'LCA',
    LI: 'LIE',
    LK: 'LKA',
    LR: 'LBR',
    LS: 'LSO',
    LT: 'LTU',
    LU: 'LUX',
    LV: 'LVA',
    LY: 'LBY',
    MA: 'MAR',
    MC: 'MCO',
    MD: 'MDA',
    ME: 'MNE',
    MF: 'MAF',
    MG: 'MDG',
    MH: 'MHL',
    MK: 'MKD',
    ML: 'MLI',
    MM: 'MMR',
    MN: 'MNG',
    MO: 'MAC',
    MP: 'MNP',
    MQ: 'MTQ',
    MR: 'MRT',
    MS: 'MSR',
    MT: 'MLT',
    MU: 'MUS',
    MV: 'MDV',
    MW: 'MWI',
    MX: 'MEX',
    MY: 'MYS',
    MZ: 'MOZ',
    NA: 'NAM',
    NC: 'NCL',
    NE: 'NER',
    NF: 'NFK',
    NG: 'NGA',
    NI: 'NIC',
    NL: 'NLD',
    NO: 'NOR',
    NP: 'NPL',
    NR: 'NRU',
    NU: 'NIU',
    NZ: 'NZL',
    OM: 'OMN',
    PA: 'PAN',
    PE: 'PER',
    PF: 'PYF',
    PG: 'PNG',
    PH: 'PHL',
    PK: 'PAK',
    PL: 'POL',
    PM: 'SPM',
    // PN: 'PCN',
    PR: 'PRI',
    PS: 'PSE',
    PT: 'PRT',
    PW: 'PLW',
    PY: 'PRY',
    QA: 'QAT',
    RE: 'REU',
    RO: 'ROU',
    RS: 'SRB',
    RU: 'RUS',
    RW: 'RWA',
    SA: 'SAU',
    SB: 'SLB',
    SC: 'SYC',
    SD: 'SDN',
    SE: 'SWE',
    SG: 'SGP',
    SH: 'SHN',
    SI: 'SVN',
    SJ: 'SJM',
    SK: 'SVK',
    SL: 'SLE',
    SM: 'SMR',
    SN: 'SEN',
    SO: 'SOM',
    SR: 'SUR',
    SS: 'SSD',
    ST: 'STP',
    SV: 'SLV',
    SX: 'SXM',
    SY: 'SYR',
    SZ: 'SWZ',
    TC: 'TCA',
    TD: 'TCD',
    //TF: 'ATF',
    TG: 'TGO',
    TH: 'THA',
    TJ: 'TJK',
    TK: 'TKL',
    TL: 'TLS',
    TM: 'TKM',
    TN: 'TUN',
    TO: 'TON',
    TR: 'TUR',
    TT: 'TTO',
    TV: 'TUV',
    TW: 'TWN',
    TZ: 'TZA',
    UA: 'UKR',
    UG: 'UGA',
    // UM: 'UMI',
    US: 'USA',
    UY: 'URY',
    UZ: 'UZB',
    VA: 'VAT',
    VC: 'VCT',
    VE: 'VEN',
    VG: 'VGB',
    VI: 'VIR',
    VN: 'VNM',
    VU: 'VUT',
    WF: 'WLF',
    WS: 'WSM',
    YE: 'YEM',
    YT: 'MYT',
    ZA: 'ZAF',
    ZM: 'ZMB',
    ZW: 'ZWE',
  };
  return countryCodeIsoAlpha3FromIsoAlpha2[alpha2];
}

/**
 * @param alpha3 Country ISO 3166-1 alpha-3 code.
 * @returns Country ISO 3166-1 alpha-2 code.
 * @url https://en.wikipedia.org/wiki/ISO_3166-1
 */
export function getCountryCodeIsoAlpha2FromIsoAlpha3(alpha3: string): CountryCode {
  const countryCodeIsoAlpha2FromIsoAlpha3: { [key: string]: CountryCode } = {
    ABW: 'AW',
    AFG: 'AF',
    AGO: 'AO',
    AIA: 'AI',
    ALA: 'AX',
    ALB: 'AL',
    AND: 'AD',
    ARE: 'AE',
    ARG: 'AR',
    ARM: 'AM',
    ASM: 'AS',
    // ATA: 'AQ',
    // ATF: 'TF',
    ATG: 'AG',
    AUS: 'AU',
    AUT: 'AT',
    AZE: 'AZ',
    BDI: 'BI',
    BEL: 'BE',
    BEN: 'BJ',
    BES: 'BQ',
    BFA: 'BF',
    BGD: 'BD',
    BGR: 'BG',
    BHR: 'BH',
    BHS: 'BS',
    BIH: 'BA',
    BLM: 'BL',
    BLR: 'BY',
    BLZ: 'BZ',
    BMU: 'BM',
    BOL: 'BO',
    BRA: 'BR',
    BRB: 'BB',
    BRN: 'BN',
    BTN: 'BT',
    // BVT: 'BV',
    BWA: 'BW',
    CAF: 'CF',
    CAN: 'CA',
    CCK: 'CC',
    CHE: 'CH',
    CHL: 'CL',
    CHN: 'CN',
    CIV: 'CI',
    CMR: 'CM',
    COD: 'CD',
    COG: 'CG',
    COK: 'CK',
    COL: 'CO',
    COM: 'KM',
    CPV: 'CV',
    CRI: 'CR',
    CUB: 'CU',
    CUW: 'CW',
    CXR: 'CX',
    CYM: 'KY',
    CYP: 'CY',
    CZE: 'CZ',
    DEU: 'DE',
    DJI: 'DJ',
    DMA: 'DM',
    DNK: 'DK',
    DOM: 'DO',
    DZA: 'DZ',
    ECU: 'EC',
    EGY: 'EG',
    ERI: 'ER',
    ESH: 'EH',
    ESP: 'ES',
    EST: 'EE',
    ETH: 'ET',
    FIN: 'FI',
    FJI: 'FJ',
    FLK: 'FK',
    FRA: 'FR',
    FRO: 'FO',
    FSM: 'FM',
    GAB: 'GA',
    GBR: 'GB',
    GEO: 'GE',
    GGY: 'GG',
    GHA: 'GH',
    GIB: 'GI',
    GIN: 'GN',
    GLP: 'GP',
    GMB: 'GM',
    GNB: 'GW',
    GNQ: 'GQ',
    GRC: 'GR',
    GRD: 'GD',
    GRL: 'GL',
    GTM: 'GT',
    GUF: 'GF',
    GUM: 'GU',
    GUY: 'GY',
    HKG: 'HK',
    // HMD: 'HM',
    HND: 'HN',
    HRV: 'HR',
    HTI: 'HT',
    HUN: 'HU',
    IDN: 'ID',
    IMN: 'IM',
    IND: 'IN',
    IOT: 'IO',
    IRL: 'IE',
    IRN: 'IR',
    IRQ: 'IQ',
    ISL: 'IS',
    ISR: 'IL',
    ITA: 'IT',
    JAM: 'JM',
    JEY: 'JE',
    JOR: 'JO',
    JPN: 'JP',
    KAZ: 'KZ',
    KEN: 'KE',
    KGZ: 'KG',
    KHM: 'KH',
    KIR: 'KI',
    KNA: 'KN',
    KOR: 'KR',
    KWT: 'KW',
    LAO: 'LA',
    LBN: 'LB',
    LBR: 'LR',
    LBY: 'LY',
    LCA: 'LC',
    LIE: 'LI',
    LKA: 'LK',
    LSO: 'LS',
    LTU: 'LT',
    LUX: 'LU',
    LVA: 'LV',
    MAC: 'MO',
    MAF: 'MF',
    MAR: 'MA',
    MCO: 'MC',
    MDA: 'MD',
    MDG: 'MG',
    MDV: 'MV',
    MEX: 'MX',
    MHL: 'MH',
    MKD: 'MK',
    MLI: 'ML',
    MLT: 'MT',
    MMR: 'MM',
    MNE: 'ME',
    MNG: 'MN',
    MNP: 'MP',
    MOZ: 'MZ',
    MRT: 'MR',
    MSR: 'MS',
    MTQ: 'MQ',
    MUS: 'MU',
    MWI: 'MW',
    MYS: 'MY',
    MYT: 'YT',
    NAM: 'NA',
    NCL: 'NC',
    NER: 'NE',
    NFK: 'NF',
    NGA: 'NG',
    NIC: 'NI',
    NIU: 'NU',
    NLD: 'NL',
    NOR: 'NO',
    NPL: 'NP',
    NRU: 'NR',
    NZL: 'NZ',
    OMN: 'OM',
    PAK: 'PK',
    PAN: 'PA',
    // PCN: 'PN',
    PER: 'PE',
    PHL: 'PH',
    PLW: 'PW',
    PNG: 'PG',
    POL: 'PL',
    PRI: 'PR',
    PRK: 'KP',
    PRT: 'PT',
    PRY: 'PY',
    PSE: 'PS',
    PYF: 'PF',
    QAT: 'QA',
    REU: 'RE',
    ROU: 'RO',
    RUS: 'RU',
    RWA: 'RW',
    SAU: 'SA',
    SDN: 'SD',
    SEN: 'SN',
    SGP: 'SG',
    // SGS: 'GS',
    SHN: 'SH',
    SJM: 'SJ',
    SLB: 'SB',
    SLE: 'SL',
    SLV: 'SV',
    SMR: 'SM',
    SOM: 'SO',
    SPM: 'PM',
    SRB: 'RS',
    SSD: 'SS',
    STP: 'ST',
    SUR: 'SR',
    SVK: 'SK',
    SVN: 'SI',
    SWE: 'SE',
    SWZ: 'SZ',
    SXM: 'SX',
    SYC: 'SC',
    SYR: 'SY',
    TCA: 'TC',
    TCD: 'TD',
    TGO: 'TG',
    THA: 'TH',
    TJK: 'TJ',
    TKL: 'TK',
    TKM: 'TM',
    TLS: 'TL',
    TON: 'TO',
    TTO: 'TT',
    TUN: 'TN',
    TUR: 'TR',
    TUV: 'TV',
    TWN: 'TW',
    TZA: 'TZ',
    UGA: 'UG',
    UKR: 'UA',
    // UMI: 'UM',
    URY: 'UY',
    USA: 'US',
    UZB: 'UZ',
    VAT: 'VA',
    VCT: 'VC',
    VEN: 'VE',
    VGB: 'VG',
    VIR: 'VI',
    VNM: 'VN',
    VUT: 'VU',
    WLF: 'WF',
    WSM: 'WS',
    YEM: 'YE',
    ZAF: 'ZA',
    ZMB: 'ZM',
    ZWE: 'ZW',
  };
  return countryCodeIsoAlpha2FromIsoAlpha3[alpha3];
}

/**
 * Formats number with the specified thousand and decimal separators
 * Example: 1234567.890 => 1,234,567.89
 *
 * @param value Value to format
 * @param decimals Decimal places to round to
 * @param shouldDisplayOriginalValue Flag to determine if original value should be displayed, take precedence
 * @param separators Thousand and Decimal separators as object
 * @returns Formatted number as string
 */
export function formatNumber(
  value: DecimalValue,
  decimals: number,
  shouldDisplayOriginalValue: boolean,
  separators: NumberFormatSeparators,
) {
  const numberDecimal = new Decimal(value);
  const numberFormatted = shouldDisplayOriginalValue
    ? value.toString()
    : numberDecimal.toFixed(decimals);

  const parts = numberFormatted.split('.');
  // note: this doesn't handle currencies like INR
  parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${separators.thousands}`);

  return parts.join(separators.decimals);
}
