import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';
import { AuthCodeState } from './reducer';

export const getAuthCode = (state: RootState) => state.authCode;

export const getItem = createSelector(getAuthCode, (state: AuthCodeState) => state.item);
export const getError = createSelector(getAuthCode, (state: AuthCodeState) => state.error);
export const getIsLoggedIn = createSelector(getAuthCode, (state: AuthCodeState) =>
  Boolean(state.item.token),
);
