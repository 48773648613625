import 'intersection-observer';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'formdata-polyfill';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'core-js/stable/regexp';

// to support window.scrollTo on IE11
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
