import { useRef, useEffect, useCallback } from 'react';

// 10 mins
const REFRESH_INTERVAL = 10 * 60 * 1000;

const useMemberKeepAlive = (isMember: boolean, refreshTokenCallback: () => void) => {
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const resetTimer = useCallback(() => {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
  }, []);

  useEffect(() => {
    function intervalRefreshTokenHandler() {
      resetTimer();

      timer.current = setInterval(() => {
        refreshTokenCallback();
      }, REFRESH_INTERVAL);
    }

    if (isMember) intervalRefreshTokenHandler();

    return () => {
      resetTimer();
    };
  }, [refreshTokenCallback, isMember, resetTimer]);
};

export default useMemberKeepAlive;
