export function loadScript(src: string, id: string = '') {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    if (document.head) {
      document.head.appendChild(script);
    }
  });
}
