import { Dictionary } from './types';

let dictionary: Dictionary;

export const setDictionary = (dic: Dictionary, callBack?: () => void) => {
  dictionary = dic;
  // Added for the widget components so that they can load the component after the translation is loaded.
  callBack && callBack();
};

export const getDictionary = () => dictionary;
