/** TODO: We should make sure of licesnse use.
 * EVEREST-3431
 * https://datahub.io/JohnSnowLabs/country-and-continent-codes-list#resource-country-and-continent-codes-list-csv
 * */
// import countries from './countryCodes.json';
import countries from './countryAndContinentCodes.json';

type countryCode = {
  Continent_Code?: string | null;
  Continent_Name?: string | null;
  Country_Name?: string | null;
  Country_Number?: number | null;
  Three_Letter_Country_Code?: string | null;
  Two_Letter_Country_Code?: string | null;
};

export const getCountryCodeByName = (name: string) => {
  const filtered = countries.filter((country: countryCode) => country['Country_Name'] === name);
  if (filtered.length !== 1) {
    console.error('cannot find country code by name: ', name);
    return null;
  }
  return filtered[0]['Three_Letter_Country_Code'];
};

export const getCountryNameByCode = (code: string) => {
  const filtered = countries.filter(
    (country: countryCode) => country['Three_Letter_Country_Code'] === code,
  );
  if (filtered.length !== 1) {
    console.error('cannot find country by name: ', code);
    return null;
  }
  return filtered[0]['Country_Name'];
};

export const getCountryTranslateIdByCode = (code: string) => {
  const filtered = countries.filter(
    (country: countryCode) => country['Three_Letter_Country_Code'] === code,
  );
  if (filtered.length !== 1) {
    console.error('cannot find country by name: ', code);
    return null;
  }
  return filtered[0]['Translation_Label_Id'];
};
