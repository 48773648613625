import React, { ReactNode } from 'react';
import { createSelector } from 'reselect';

import { Dictionary } from '@travel/translation';
import { as } from '@travel/utils';

import { I18nTypes } from '../';

export type State = {
  _i18n: I18nTypes;
};

type VariantDictionary = { [key: string]: { [key: string]: string } };
type Option = { text: string; value: string };

const getLanguage = (state: State) => {
  return state._i18n.language;
};

const getMarket = (state: State) => {
  return state._i18n.market;
};

const getCurrency = (state: State) => {
  return state._i18n.currency;
};

const getIsGlobalCurrency = (state: State) => {
  return state._i18n.isGlobalCurrency;
};

const getSupportedLanguages = (state: State) => {
  return state._i18n.supportedLanguages;
};

const getDictionary = (state: State | any) => {
  return as<Dictionary>(state._i18n.dictionary);
};

const getSupportedLanguageOptions = (state: State): Option[] => {
  const arr = state._i18n.supportedLanguages;
  if (!arr) return [];
  return arr
    .sort((a, b) => a?.enName?.localeCompare(b.enName))
    .map(supportedLanguage => ({
      text: supportedLanguage.name,
      value: supportedLanguage.code,
    }));
};

//get variant string from object id file using object id of props
export const getI18nText = (state: State, props: any) => {
  let text = '';
  if (props.language) {
    if (
      !Object.prototype.hasOwnProperty.call(state._i18n.allDictionary[props.language], props.id)
    ) {
      console.warn(`The translation item "${props.id}" cannot be found in allDictionary`);
      text = 'NULL';
    } else {
      text = state._i18n.allDictionary[props.language][props.id];
    }
  } else if (!Object.prototype.hasOwnProperty.call(state._i18n.dictionary, props.id)) {
    console.warn(`The translation item "${props.id}" cannot be found`);
    text = 'NULL';
  } else {
    text = state._i18n.dictionary[props.id];
  }
  return text;
};

const getI18nParams = (_state: State, props: any) => {
  const variants = {};
  if (props.variant) Object.assign(variants, props.variant);
  if (props.data) Object.assign(variants, props.data);
  return variants;
};

const getI18nCount = (_state: State, props: any) => {
  return props.count;
};

const getKey = (_state: State, props: any) => {
  return props.vKey;
};

const getObjectId = (_state: State, props: any) => {
  return props.id;
};

const getLinkInfo = (_state: State, props: any) => {
  return props.linkInfo;
};

const getVariantDictionary = (state: State, props: any) => {
  if (props.language) {
    return state._i18n.allVariantDictionary[props.language];
  }
  return state._i18n.variantDictionary;
};

const handleCount = (originalText: string[] | string, language: string, count: number) => {
  //TODO Andres: refactor this for immutability
  let text = originalText;
  // Check if is a pluralized translation
  if (text instanceof Array) {
    if (count === undefined) {
      text = '';
      console.warn('[i18n] Missing "count" property');
    } else if (isNaN(parseInt(`${count}`, 10))) {
      text = '';
      console.warn('[i18n] Invalid "count" property');
    } else {
      // Select the translation according the the count value
      switch (language) {
        // Languages with plural rule #0
        // https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals#Plural_rule_0_(1_form)
        case 'id':
        case 'ja':
        case 'ko':
        case 'th':
        case 'zh-cn':
        case 'zh-hk':
        case 'zh-tw':
          text = text[0];
          break;
        // Languages with plural rule #1
        // https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals#Plural_rule_1_(2_forms)
        case 'de':
        case 'en-US':
        case 'es':
        case 'it':
        case 'vi':
        case 'vi-vn':
          text = count === 1 ? text[0] : text[1];
          break;
        // Languages with plural rule #2
        // https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals#Plural_rule_2_(2_forms)
        case 'fr':
          text = count === 0 || count === 1 ? text[0] : text[1];
          break;
        default:
          text = '';
          console.warn('[i18n] Pluralization not supported for the given language');
      }
      // Replace "count" data token inside the translated text
      text = text.replace(/{_count_}/g, `${count}`);
    }
  }
  return text;
};

const checkAndSplitVariantString = (value: string) => {
  return value.includes('{{') ? value.split(/{{([^}]+)}}/g) : value.split(/{([^}]+)}/g);
};

export type LinkInfo = {
  link: string;
  target: string;
  linkCallback: () => void;
};

//Handle Case 4 and Case 5 here and return plain string
const getSubI18nVariant = (
  parts: string[],
  variantKeys: string[],
  variantDictionary: VariantDictionary,
  params: { [key: string]: string },
  linkInfo?: { [key: string]: LinkInfo },
) => {
  const result = parts.map((value, index) => {
    if (index % 2 === 1) {
      let variantDictionaryParts: Array<string | ReactNode> = [];
      if (value in variantDictionary) {
        const variantDictionaryObject = variantDictionary[value];
        //get variants by using vKey Array
        variantKeys.forEach((key, keyIndex) => {
          let variantDictionaryString: string = `{{${value}}}`;
          let variantDictionaryLink: ReactNode = null;

          if (key in variantDictionaryObject) {
            variantKeys.splice(keyIndex, 1);

            variantDictionaryString = variantDictionaryObject[key];
            if (variantDictionaryString.includes('{{')) {
              //Solve this variantDictionaryString here and return here
              const parts = checkAndSplitVariantString(variantDictionaryString);
              variantDictionaryString = getSubI18nVariant(
                parts,
                variantKeys,
                variantDictionary,
                params,
              )
                .join('')
                .trim();
            }

            if (value.startsWith('Linked')) {
              const linkInfoObject =
                linkInfo && linkInfo[value]
                  ? linkInfo[value]
                  : {
                      link: '#',
                      target: '_self',
                      linkCallback: null,
                    };

              variantDictionaryLink = React.createElement(
                'a',
                {
                  href: linkInfoObject.link,
                  target: linkInfoObject.target,
                  onClick: linkInfoObject.linkCallback,
                  key: `linkKey_${index}`,
                },
                variantDictionaryString,
              );
            }
          }

          variantDictionaryParts.push(variantDictionaryLink || variantDictionaryString);
        });
      } else if (typeof params[value] !== 'undefined') {
        return params[value];
      }
      return variantDictionaryParts.length > 0 ? variantDictionaryParts : `{{${value}}}`;
    }

    return value;
  });

  return result;
};

const getI18nParsedText = (
  language: string,
  originalText: string,
  params: { [key: string]: string } = {},
  count: number,
  vKey: string | string[],
  _objectId: string,
  variantDictionary: VariantDictionary,
  linkInfo: { [key: string]: LinkInfo },
) => {
  const text = handleCount(originalText, language, count);
  const parts = checkAndSplitVariantString(text);
  //If not vKey than Case 1 and Case 2 Solved -> Only Check in Params
  if (!vKey) {
    return parts.map((value, index) => {
      //PATCH FIX: Old strategy used for tackling Special case
      if (index % 2 === 1) {
        if (params[value] === '') return '';
        return typeof params[value] !== 'undefined' ? params[value] : `{{${value}}}`;
      }
      return value;
    });
  }

  //If vKey Exist than Case 3,Case 4, and Case 5 Solved
  //Make a vKey array (as vKey can be one or can be multiple in array so better common vKey Array
  let variantKeys: Array<string> = [];
  variantKeys = Array.isArray(vKey) ? vKey : [vKey];
  return getSubI18nVariant(parts, variantKeys, variantDictionary, params, linkInfo);
};

const getI18nTextSelector = createSelector(
  [
    getLanguage,
    getI18nText,
    getI18nParams,
    getI18nCount,
    getKey,
    getObjectId,
    getVariantDictionary,
    getLinkInfo,
  ],
  (
    language: string,
    originalText,
    params = {},
    count,
    vKey,
    objectId,
    variantDictionary: VariantDictionary,
    linkInfo,
  ) => {
    const I18nText = getI18nParsedText(
      language,
      originalText,
      params,
      count,
      vKey,
      objectId,
      variantDictionary,
      linkInfo,
    );

    return <>{I18nText}</>;
  },
);

const getI18nArraySelector = createSelector(
  [
    getLanguage,
    getI18nText,
    getI18nParams,
    getI18nCount,
    getKey,
    getObjectId,
    getVariantDictionary,
    getLinkInfo,
  ],
  (
    language: string,
    originalText,
    params = {},
    count,
    vKey,
    objectId,
    variantDictionary: VariantDictionary,
    linkInfo,
  ) => {
    return getI18nParsedText(
      language,
      originalText,
      params,
      count,
      vKey,
      objectId,
      variantDictionary,
      linkInfo,
    );
  },
);

export {
  getI18nArraySelector,
  getI18nTextSelector,
  getLanguage,
  getMarket,
  getCurrency,
  getIsGlobalCurrency,
  getSupportedLanguages,
  getSupportedLanguageOptions,
  getDictionary,
};
