import React, { MouseEvent, ReactNode } from 'react';

import { cx } from '@travel/utils';

import styles from './link.module.scss';

type Props = {
  url: string;
  label: ReactNode;
  className?: string;
  haveUnderline?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  target?: '_self' | '_blank' | '_parent' | '_top';
};

const Link = (props: Props) => {
  const { url, label, className, haveUnderline = false, onClick, target } = props;
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <a
      onClick={handleClick}
      href={url}
      className={cx(styles.link, className, haveUnderline && styles.underline)}
      target={target}
    >
      {label}
    </a>
  );
};

export default Link;
