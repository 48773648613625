import { combineReducers } from 'redux';

import { reducer as httpRequestReducer } from '@travel/http-request';
import { reducer as i18nReducer } from '@travel/i18n';

import authCodeReducer from './authCode/reducer';
import commonErrorReducer from './commonError/reducer';
import furusatoNozeiReducer from './furusatoNozei/reducer';
import rakutenMobileReducer from './rakutenMobile/reducer';
import reservationsListReducer from './reservationsList/reducer';
import userAgentReducer from './userAgent/reducer';

import routerReducer from './__router/reducer';
import bannerReducer from './banner/reducer';
import pitariReducer from './pitari/reducer';

export default combineReducers({
  _i18n: i18nReducer,
  _httpRequest: httpRequestReducer,
  router: routerReducer,
  commonError: commonErrorReducer,
  userAgent: userAgentReducer,
  authCode: authCodeReducer,
  reservationsList: reservationsListReducer,
  pitari: pitariReducer,
  banner: bannerReducer,
  furusatoNozei: furusatoNozeiReducer,
  rakutenMobile: rakutenMobileReducer,
});
