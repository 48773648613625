let supportWebP = false;
let supportJP2 = false;
let supportJPEGXR = false;
if (typeof Image !== 'undefined') {
  const WebP = new Image();
  WebP.onerror = () => {
    if (WebP.height === 2) {
      supportWebP = true;
    }
  };
  WebP.onload = WebP.onerror;
  WebP.src =
    'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
  const JP2 = new Image();
  JP2.onerror = () => {
    if (JP2.height === 30) {
      supportJP2 = true;
    }
  };
  JP2.onload = JP2.onerror;
  JP2.src =
    'data:image/jp2;base64,AAAADGpQICANCocKAAAAFGZ0eXBqcDIgAAAAAGpwMiAAAAAtanAyaAAAABZpaGRyAAAAHgAAAB4ABAcHAAAAAAAPY29scgEAAAAAABAAAAAAanAyY/9P/1EAMgAAAAAAHgAAAB4AAAAAAAAAAAAAAB4AAAAeAAAAAAAAAAAABAcBAQcBAQcBAQcBAf9kACMAAUNyZWF0b3I6IEphc1BlciBWZXJzaW9uIDEuOTAwLjH/UgAMAAAAAQAFBAQAAf9cABNAQEhIUEhIUEhIUEhIUEhIUP9dABQBQEBISFBISFBISFBISFBISFD/XQAUAkBASEhQSEhQSEhQSEhQSEhQ/10AFANAQEhIUEhIUEhIUEhIUEhIUP+QAAoAAAAAADIAAf+T34AIB9+ACAffgAgHz7QEAICAgICAgICAgICAgICAgICAgICA/9k=';
  const JPEGXR = new Image();
  JPEGXR.onerror = () => {
    if (JPEGXR.height === 2) {
      supportJPEGXR = true;
    }
  };
  JPEGXR.onload = JPEGXR.onerror;
  JPEGXR.src =
    'data:image/vnd.ms-photo;base64,SUm8AQgAAAAFAAG8AQAQAAAASgAAAIC8BAABAAAAAQAAAIG8BAABAAAAAgAAAMC8BAABAAAAWgAAAMG8BAABAAAARgAAAAAAAAAkw91vA07+S7GFPXd2jckQV01QSE9UTwAZAMFxAAAAATAAoAAKAACgAAAQgCAIAAAEb/8AAQAAAQDCPwCAAAAAAAAAAAAAAAAAjkI/AIAAAAAAAAABIAA=';
}

export function akamai({
  url,
  width,
  height,
  supports,
}: {
  url: string;
  width?: number;
  height?: number;
  supports?: any;
}): string {
  if (!url) {
    return '';
  }

  let akamaiUrl = url.replace('image.space.rakuten.co.jp', 'trvis.r10s.jp');

  if (akamaiUrl.includes('trvis.r10s.jp') || akamaiUrl.includes('trvimg.r10s.jp')) {
    akamaiUrl = akamaiUrl.replace('http:', 'https:');
    let format = '';
    if (supports) {
      const { jp2, webp, jpegxr } = supports;
      supportJP2 = supportJP2 || jp2;
      supportWebP = supportWebP || webp;
      supportJPEGXR = supportJPEGXR || jpegxr;
    }

    if (supportJP2 || supportWebP || supportJPEGXR) {
      format += `&output-format=${supportWebP ? 'webp' : supportJP2 ? 'jp2' : 'jpegxr'}`;
    }
    const separator = akamaiUrl.includes('?') ? '&' : '?';
    if (width) {
      akamaiUrl = `${akamaiUrl}${separator}resize=${width}:*${format}`;
    } else if (height) {
      akamaiUrl = `${akamaiUrl}${separator}resize=*:${height}${format}`;
    }
  }

  return akamaiUrl;
}
