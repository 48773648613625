import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchBannerAsync } from './actions';

import { ResponseBody } from 'Banner-Types';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchBannerAsync.request], () => true)
  .handleAction([fetchBannerAsync.success, fetchBannerAsync.failure], () => false);

export const item = createReducer({} as ResponseBody).handleAction(
  fetchBannerAsync.success,
  (_state, action) => action.payload,
);

export const isError = createReducer(false)
  .handleAction(fetchBannerAsync.failure, () => true)
  .handleAction(fetchBannerAsync.success, () => false);

const bannerReducer = combineReducers({
  isFetching,
  item,
  isError,
});

export default bannerReducer;
export type BannerState = ReturnType<typeof bannerReducer>;
