import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { clearCommonError, setCommonError, setIsUIImplementationError } from './actions';

import { CommonError } from 'CommonError-Types';

export const item = createReducer({} as CommonError)
  .handleAction(setCommonError, (_state, action) => action.payload)
  .handleAction(clearCommonError, () => ({}));

export const isUIImplementationError = createReducer(false).handleAction(
  setIsUIImplementationError,
  (_state, action) => action.payload,
);

const commonErrorReducer = combineReducers({
  item,
  isUIImplementationError,
});

export default commonErrorReducer;
export type CommonErrorState = ReturnType<typeof commonErrorReducer>;
