import React, { SVGProps } from 'react';

import cx from './_utils/classnames';
import getDisplayName from './_utils/hoc';

import styles from './svgIcon.module.scss';

export type IconColor =
  | 'default'
  | 'cilantro'
  | 'sunflower'
  | 'appleRed'
  | 'blueRaspberry'
  | 'cyberGrape'
  | 'white'
  | 'lightGrey'
  | 'pastelGray'
  | 'sonicSilver'
  | 'lincolnGreen'
  | 'cloudyGray'
  | 'darkCyan'
  | 'deepBlue'
  | 'chocolate'
  | 'currentColor';

export type Props = {
  // Due to inconsistency of icon paths, color default value has been removed.
  // If an icon has multiple paths, use className to pick a path to paint.
  color?: IconColor;
  size?: 12 | 16 | 24 | 32 | 48 | number;
} & SVGProps<SVGSVGElement>;

export const COLORS = {
  default: '#333',
  cilantro: '#54822B',
  sunflower: '#FFBA00',
  appleRed: '#D7402E',
  blueRaspberry: '#007CB5',
  cyberGrape: '#553E80',
  white: '#FFFFFF',
  pastelGray: '#CCCCCC',
  lightGrey: '#EAEAEA',
  sonicSilver: '#767676',
  lincolnGreen: '#255500',
  cloudyGray: '#8c8c8c',
  darkCyan: '#1d54a7',
  deepBlue: '#13366a',
  chocolate: '#BB5400',
  currentColor: 'currentColor',
};

export default function withIconProps(
  WrappedComponent: any,
  options: {
    // for some icons which is not a square, we don't want to put a default size like 24x24.
    // we can either turn off shouldSetDefaultSize to not change the icon's size, or set
    // defaultWidth and defaultHeight manually to overwrite the size
    shouldSetDefaultSize?: boolean;
    defaultWidth?: number;
    defaultHeight?: number;
  } = {
    shouldSetDefaultSize: true,
    defaultWidth: 24,
    defaultHeight: 24,
  },
) {
  function NewComponent(props: Props) {
    const { size, color, className, ...rest } = props;

    const style = {
      height: props.size,
      width: props.size,
    };

    if (options.shouldSetDefaultSize) {
      style.height = style.height || options.defaultHeight;
      style.width = style.width || options.defaultWidth;
    }

    return (
      <WrappedComponent
        {...rest}
        className={cx(styles.svgIcon, className)}
        color={color && COLORS[color]}
        style={style}
      />
    );
  }

  NewComponent.displayName = `withIconProps(${getDisplayName(WrappedComponent)})`;

  NewComponent.defaultProps = {
    color: 'default',
  };

  return NewComponent;
}
