import React from 'react';

import { ArrowRight, Faq } from '@travel/icons/ui';
import { Translate } from '@travel/translation';
import { useTranslation } from '@travel/traveler-core/hooks';
import { Link } from '@travel/ui';

import styles from './domesticErrorPage.module.scss';

type Props = {
  textLabelId: string;
  urlLabelId: string;
};

function FaqLink(props: Props) {
  const url = useTranslation({ id: props.urlLabelId });

  return (
    <Link
      url={url}
      className={styles.faqItem}
      label={
        <>
          <Faq size={20} className={styles.faqIcon} />
          <Translate id={props.textLabelId} className={styles.faqText} />
          <ArrowRight size={16} className={styles.faqIcon} />
        </>
      }
    />
  );
}

export default FaqLink;
