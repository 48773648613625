import { useEffect, useLayoutEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import env from '@travel/env';

import { logout } from 'store/authCode/actions';
import { getError, getItem } from 'store/authCode/selectors';

const useHeaderEvents = () => {
  const dispatch = useDispatch();
  // if local env (mock-api), we will not trigger OMNI when token does not exist
  const isDevelopmentEnv = env('UNIVERSAL_API_MOCK_SERVER') === 'true';

  const item = useSelector(getItem);
  const error = useSelector(getError);

  const isMaintenanceMode = error?.status === 503;

  useLayoutEffect(() => {
    // to trigger login if there is no token

    // mock-env: skip forced user login on any error condition
    // non-mock-env: whenever there is no token or auth api is error, force user to login
    const triggerLogin = () => {
      if (!isMaintenanceMode && !isDevelopmentEnv && (!item?.token || Boolean(error?.status))) {
        const loginEvent = new Event('HEADER_EVENT_LOGIN');
        document.dispatchEvent(loginEvent);
      }
    };
    window.addEventListener('load', triggerLogin, false);

    return () => window.removeEventListener('load', triggerLogin, false);
  }, [error?.status, isDevelopmentEnv, isMaintenanceMode, item?.token]);

  // to register logout event
  useEffect(() => {
    function triggerLogout() {
      dispatch(logout());
    }

    document.addEventListener('HEADER_EVENT_LOGOUT', triggerLogout, false);
    return () => {
      document.removeEventListener('HEADER_EVENT_LOGOUT', triggerLogout, false);
    };
  }, [dispatch]);

  // to replace url to href path as the redirect url will have "?" (current platform rule)
  // only case "<url>?" after OMNI login will be replaced
  useEffect(() => {
    const pathRegex = new RegExp('\\?$');
    if (pathRegex.test(window.location.href)) {
      const href = window.location.href;
      window.history.replaceState({}, '', href.substring(0, href.length - 1));
    }
  });
};

export default useHeaderEvents;
