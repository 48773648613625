import * as types from '../actions';
const initialState = {
  dictionary: {},
  variantDictionary: {},
  language: '',
  localeForDates: '', // dates formatted with moment.js
  localeForOther: '', // numbers, measurement, currency, name and phone
  market: {
    marketCode: '',
    timeZone: '',
    supportedCurrencies: [],
    membershipStatus: 0,
    displayOrder: [],
  },
  supportedLanguages: [],
  // XXX: either dictionary/variantDictionary or allDictionary/allVariantDictionary should be set.
  // allDictionary/allVariantDictionary is for displaying multiple language contents on one page.
  // This package distinguish these 2 situations by looking if there is a 'language' prop, so please remember
  // to pass language prop to every <I18n> to use allDictionary/allVariantDictionary.
  allDictionary: {},
  allVariantDictionary: {},
  fallbackLanguages: {},
  currency: '',
  terms: {},
  isGlobalCurrency: false,
  labels: {},
};

export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case types.SET_TERMS:
      return Object.assign({}, state, {
        terms: action.payload,
      });
    case types.SET_LABELS:
      return Object.assign({}, state, {
        labels: action.payload,
      });
    case types.SET_DICTIONARY:
      return Object.assign({}, state, {
        dictionary: action.dictionary,
      });
    case types.SET_DICTIONARY_ALL_LANGUAGE:
      return Object.assign({}, state, {
        allDictionary: action.allDictionary,
      });
    case types.SET_VARIANT_DICTIONARY:
      return Object.assign({}, state, {
        variantDictionary: action.variantDictionary,
      });
    case types.SET_ALL_VARIANT_DICTIONARY:
      return Object.assign({}, state, {
        allVariantDictionary: action.allVariantDictionary,
      });
    case types.SET_LANGUAGE_SUPPORTED_FOR_I18N:
      return Object.assign({}, state, {
        language: action.language,
      });
    case types.SET_LOCALE_SUPPORTED_FOR_L10N_DATES:
      return Object.assign({}, state, {
        localeForDates: action.locale,
      });
    case types.SET_LOCALE_SUPPORTED_FOR_L10N_OTHER:
      return Object.assign({}, state, {
        localeForOther: action.locale,
      });
    case types.SET_USER_MARKET:
      return Object.assign({}, state, {
        market: action.market,
      });
    case types.SET_SUPPORTED_LANGUAGES:
      return Object.assign({}, state, {
        supportedLanguages: action.supportedLanguages,
      });
    case types.SET_CURRENCY:
      return Object.assign({}, state, {
        currency: action.currency,
      });
    case types.SET_IS_GLOBAL_CURRENCY:
      return Object.assign({}, state, {
        isGlobalCurrency: action.isGlobalCurrency,
      });
    case types.SET_FALLBACK_LANGUAGES:
      return Object.assign({}, state, {
        fallbackLanguages: action.fallbackLanguages,
      });
    default:
      return state;
  }
}
