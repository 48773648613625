import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { fetchCouponListAsync, submitCouponAsync } from 'store/furusatoNozei/actions';
import { fetchMobileCouponListAsync, submitMobileCouponAsync } from 'store/rakutenMobile/actions';

import { CouponStatus, Status } from 'constants/reservations';
import { ResponseBody } from 'ReservationsList-Types';
import {
  closeQrDialog,
  fetchMoreReservationListAsync,
  fetchReservationsListAsync,
  openQrDialog,
  setCurrentTab,
} from './actions';

export const isFetching = createReducer(false as boolean)
  .handleAction([fetchReservationsListAsync.request], () => true)
  .handleAction(
    [fetchReservationsListAsync.success, fetchReservationsListAsync.failure],
    () => false,
  );

export const isFetchingMore = createReducer(false as boolean)
  .handleAction(fetchMoreReservationListAsync.request, () => true)
  .handleAction(
    [fetchMoreReservationListAsync.success, fetchMoreReservationListAsync.failure],
    () => false,
  );

export const isQrDialogOpen = createReducer(false as boolean)
  .handleAction(openQrDialog, () => true)
  .handleAction(closeQrDialog, () => false);

export const items = createReducer({} as ResponseBody)
  .handleAction(fetchReservationsListAsync.success, (_state, action) => action.payload)
  .handleAction(fetchMoreReservationListAsync.success, (state, action) => ({
    ...state,
    offset: action.payload.offset,
    limit: action.payload.limit,
    reservations: state.reservations.concat(action.payload.reservations),
  }))
  .handleAction(submitCouponAsync.success, (state, action) => {
    const payload = action.payload;
    const newReservations = state.reservations.map(reservation => {
      if (reservation.reservationId === payload.reservationId) {
        return { ...reservation, hometownTaxCouponStatus: CouponStatus.ALREADY_APPLIED };
      }
      return reservation;
    });
    return { ...state, reservations: newReservations };
  })
  .handleAction(fetchCouponListAsync.failure, (state, action) => {
    const payload = action.payload;
    const newReservations = state.reservations.map(reservation => {
      if (reservation.reservationId === payload.reservationId) {
        return { ...reservation, hometownTaxCouponStatus: CouponStatus.DISABLE };
      }
      return reservation;
    });
    return { ...state, reservations: newReservations };
  })
  .handleAction(submitMobileCouponAsync.success, (state, action) => {
    const payload = action.payload;
    const newReservations = state.reservations.map(reservation => {
      if (reservation.reservationId === payload.reservationId) {
        return { ...reservation, mobileCouponStatus: CouponStatus.ALREADY_APPLIED };
      }
      return reservation;
    });
    return { ...state, reservations: newReservations };
  })
  .handleAction(fetchMobileCouponListAsync.failure, (state, action) => {
    const payload = action.payload;
    const newReservations = state.reservations.map(reservation => {
      if (reservation.reservationId === payload.reservationId) {
        return { ...reservation, mobileCouponStatus: CouponStatus.DISABLE };
      }
      return reservation;
    });
    return { ...state, reservations: newReservations };
  });

export const currentTab = createReducer(Status.UPCOMING as Status).handleAction(
  setCurrentTab,
  (_state, action) => action.payload,
);

export const qrCodeReservationId = createReducer('' as string)
  .handleAction(openQrDialog, (_state, action) => action.payload)
  .handleAction(closeQrDialog, () => '');

const reservationListReducer = combineReducers({
  isFetchingMore,
  isFetching,
  isQrDialogOpen,
  items,
  currentTab,
  qrCodeReservationId,
});

export default reservationListReducer;
export type ReservationListState = ReturnType<typeof reservationListReducer>;
