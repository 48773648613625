import { createStandardAction } from 'typesafe-actions';

import { RanData, ShortUrl, ShortUrlState } from './types';

export const updateRanData = createStandardAction('UPDATE_RAN_DATA')<RanData>();

export const setShortUrl = createStandardAction('SET_SHORT_URL')<ShortUrl>();

export const updateShortUrl = createStandardAction('UPDATE_SHORT_URL')<{
  [key: string]: ShortUrlState;
}>();
