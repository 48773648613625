export const languageToLocale: { [key: string]: string } = {
  'ja-jp': 'ja',
  'zh-tw': 'zh-tw',
  'zh-hk': 'zh-hk',
  'zh-cn': 'zh-cn',
  'ko-kr': 'ko',
  'en-us': 'en',
  'id-id': 'id',
  'th-th': 'th',
  'vi-vn': 'vi',
};

export const getDayjsLocale = (language: string) =>
  languageToLocale[language?.toLocaleLowerCase()] || 'en';
