import React from 'react';
import { useSelector } from 'react-redux';

import { getMarket } from '../selectors';
import { getNameFormat } from '../utils/l10n';

type Props = {
  market?: string;
  firstName: string;
  lastName: string;
  locale?: string;
};

function getL10nName(market: string, firstName: string, lastName: string) {
  return getNameFormat(market)
    .replace('{first}', firstName)
    .replace('{last}', lastName);
}

function L10nName({ market, firstName, lastName }: Props) {
  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  return <span>{getL10nName(market || marketFromStore, firstName, lastName)}</span>;
}

export default L10nName;
