import { Store } from 'typesafe-actions';

import { EMPTY_OBJECT } from 'constants/defaultValue';

type RequestInterceptorOptions = {
  options: any;
  path: string;
  url: string;
  isThirdPartyApi: boolean;
};

const requestInterceptor = (store: Store) => ({
  options,
  url,
  isThirdPartyApi,
}: RequestInterceptorOptions) => {
  const state = store.getState();
  const { normalizedCookies } = state._httpRequest;
  const { currency, language, market } = state._i18n;

  let verbose = false;
  if (normalizedCookies) {
    verbose = 'trv-very-verbose' in normalizedCookies;
  }

  // To make sure that we will send user credential only if url is under the same origin for 3rd party
  const credentials = isThirdPartyApi ? 'same-origin' : options.credentials;

  const {
    item: { token },
  } = store.getState().authCode;
  const result = {
    url,
    options: {
      ...options,
      credentials,
      headers: isThirdPartyApi
        ? EMPTY_OBJECT
        : {
            ...options.headers,
            'trv-device-id': normalizedCookies['trv-device-id']?.value,
            'trv-currency': currency,
            'trv-language': language,
            'trv-market': market?.marketCode,
            'trv-designation': 'jrdp',
          },
    },
    verbose,
  };

  if (token && !isThirdPartyApi) {
    result.options.headers.Authorization = token;
  }

  return result;
};

export default requestInterceptor;
