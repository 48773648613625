const pathSymbol: unique symbol = Symbol.for('@path');
const pathDispatch: unique symbol = Symbol.for('@dispatch');

type Dispatch = (action: any) => any;
type WithPathMetaData = {
  [pathSymbol]: string[];
  [pathDispatch]: Dispatch;
};
type DispatchableWithMetaData = (action: any) => any & WithPathMetaData;
export type PropDispatch = Dispatch | DispatchableWithMetaData;
export type State = any; // TODO: ask Chris to revise;

const usePath = (
  path: string,
  dispatch: PropDispatch,
): [(state: State, defaultValue: any) => State, (value: any) => void] => {
  let newPath: string[] = [];
  let realDispatch = dispatch;
  const has = Function.call.bind(Object.hasOwnProperty);
  let checkDispatch: WithPathMetaData = (dispatch as unknown) as WithPathMetaData;
  if (typeof checkDispatch[pathSymbol] !== 'undefined') {
    newPath = checkDispatch[pathSymbol].slice();
    realDispatch = checkDispatch[pathDispatch];
  }

  newPath = [
    ...newPath,
    ...path
      .replace(/\[|\]/g, '.')
      .split('.')
      .filter((a: string) => a),
  ];

  const getter = (state: State, defaultValue: any) => {
    let paths = newPath.slice();
    let part: string | undefined = '';
    let actual = state;
    /* eslint-disable no-cond-assign */
    while ((part = paths.shift())) {
      if (has(actual || {}, part)) {
        actual = actual[part];
      } else {
        return defaultValue;
      }
    }
    return actual;
  };

  const wrappedDispatch: DispatchableWithMetaData = (value: any) => {
    realDispatch({ type: 'usePath', value, path: newPath });
  };
  const wrappedDispatchWithMeta = (wrappedDispatch as unknown) as WithPathMetaData;
  wrappedDispatchWithMeta[pathSymbol] = newPath;
  wrappedDispatchWithMeta[pathDispatch] = realDispatch;
  return [getter, wrappedDispatch];
};

export default usePath;
