import { Action } from 'history';
import { Dispatch } from 'redux';
import { createStandardAction, RootAction, RootState, ThunkDependencies } from 'typesafe-actions';

import { Location } from 'core/universalRouter/types';

export const locationChangeBegin = createStandardAction('[Router] LOCATION_CHANGE_BEGIN')();
export const locationChangeEnd = createStandardAction('[Router] LOCATION_CHANGE_END')<{
  location: Location;
  action: Action;
  protocol?: string;
}>();
export const setBaseUrl = createStandardAction('[Router] SET_BASEURL')<string>();
export const setHost = createStandardAction('[Router] SET_HOST')<string>();

const toLocationObject = (to: Location | string) => {
  if (typeof to === 'string') {
    const [pathname, search] = to.split('?');
    return new Location({ pathname, search });
  }
  return to;
};

export const pushLocation = (to: Location | string): any => (
  _dispatch: Dispatch<RootAction>,
  getState: () => RootState,
  { history, res }: ThunkDependencies,
) => {
  const { baseUrl } = getState().router;
  to = toLocationObject(to);

  if (history) {
    history.push(to);
  } else if (res) {
    let path = to.pathname || '/';
    if (to.search) path = path + '?' + to.search;
    res.redirect(baseUrl + path);
  }
};

// **
// NOT IN USED
// **
// export const replaceLocation = (to: Location | string): any => (
//   _dispatch: Dispatch<RootAction>,
//   _getState: () => RootState,
//   { history }: ThunkDependencies,
// ) => {
//   if (history) {
//     history.replace(toLocationObject(to));
//   }
// };

// export const backLocation = (): any => (
//   _dispatch: Dispatch<RootAction>,
//   _getState: () => RootState,
//   { history }: ThunkDependencies,
// ) => {
//   if (history) {
//     history.goBack();
//   }
// };
