import './polyfill';
import './public-path';

import { h, render } from 'preact';
import { Provider as ReduxProvider } from 'react-redux';

// import { Provider as ReduxProvider } from 'react-redux';
import { loadableReady } from '@loadable/component';
import { createBrowserHistory } from 'history';
import configureStore from 'store';

import { ApiClientProvider } from '@travel/api-client';
import env from '@travel/env';
import { getDeviceType } from '@travel/traveler-core/utils';
import UIComponentContext from '@travel/ui/contexts';

import { createApiClient } from 'core/httpClient';
import commonErrorHandler from 'core/httpClient/commonErrorHandler';
import requestInterceptor from 'core/httpClient/requestInterceptor';
import responseInterceptor from 'core/httpClient/responseInterceptor';
import universalRouter from 'core/universalRouter';
// import initTranslation from 'core/translate/init';
// import { getSsrDeviceType } from 'store/userAgent/selectors';
import { Link } from 'core/universalRouter/Link';
import Router from 'core/universalRouter/Router';

if (env('UNIVERSAL_PREACT_DEVTOOL_ENABLE') === 'true') {
  require('preact/debug');
  require('preact/devtools');
}

const apiClient = createApiClient();

const history = createBrowserHistory({
  basename: window.PRELOADED_STATE.router.baseUrl,
});
const ssrDeviceType = getDeviceType(window.PRELOADED_STATE.userAgent);
const store = configureStore(window.PRELOADED_STATE, history, undefined, undefined, apiClient);

apiClient.setRequestInterceptor(requestInterceptor(store));
apiClient.setResponseInterceptor(responseInterceptor(store));
apiClient.setCommonErrorHandler(commonErrorHandler(store));

// initialize labels
// initTranslation(store.getState().labels.dictionary || {});

loadableReady(async () => {
  const router = universalRouter({ store });
  const children = await router.resolve({
    ...history.location,
    skipResolver: true,
  });

  render(
    <UIComponentContext.Provider
      value={{
        ssrDeviceType,
        LinkComponent: ({ href, children, ...rest }) => (
          <Link to={href || ''} {...rest}>
            {children}
          </Link>
        ),
      }}
    >
      <ApiClientProvider apiClient={apiClient}>
        <ReduxProvider store={store}>
          <Router history={history} router={router}>
            {children}
          </Router>
        </ReduxProvider>
      </ApiClientProvider>
    </UIComponentContext.Provider>,
    document.getElementById('root') as any,
  );
});
