import url from 'url';
import { track, noticeError } from '@travel/tracker';

/**
 * Helper function to stringify types other than strings
 * @param value - value to be converted to string
 * @returns {string}
 */
export function objectToString(value) {
  return typeof value == 'string' ? value : JSON.stringify(value);
}

/**
 * Helper function to split req/res objects to increase readability in newRelic
 * @param prefix - name of the object to add as the prefix of the key
 * @param object - req/res objects to be split and spread
 * @returns {Object}
 */
export function addPrefixToObjectKeys(prefix, object) {
  if (!object) return {};
  return Object.entries(object).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`${prefix}.${key}`]: objectToString(value),
    };
  }, {});
}

export function noticeApiError(type, { url, req, res, json, traceId, spanId }) {
  const parsed = json ? Promise.resolve(json) : res && res.json ? res.json() : Promise.resolve({});
  parsed.then(resBody => {
    const reqHeaders = addPrefixToObjectKeys('req.headers', req.headers);
    const params = {
      ...reqHeaders,
      url,
      'req.body': objectToString(req.body),
      'res.headers': objectToString(res.headers),
      'res.body': objectToString(resBody),
      status: res.status,
      traceId,
      spanId,
    };
    noticeError(type, params);
    track(type, params);
  });
}

export function getOptions({ values, method, headers: rawHeaders }) {
  let headers = rawHeaders;
  let options = {
    method: method,
    headers,
    mode: 'cors',
  };
  if (['POST', 'PUT', 'PATCH'].includes(method)) {
    if (typeof FormData !== 'undefined' && values instanceof FormData) {
      // Data type form
      return { ...options, body: values };
    } else {
      // Data type file
      Object.keys(values).forEach(key => {
        if (
          (typeof File !== 'undefined' && values[key] instanceof File) ||
          (typeof FileList !== 'undefined' && values[key] instanceof FileList)
        ) {
          const body = new FormData();

          Object.keys(values).forEach(key => {
            const item = values[key];
            if (item instanceof File) {
              body.append(key, item, item.name);
            } else if (item instanceof FileList) {
              for (let i = 0; i < item.length; i += 1) {
                const file = item[i];
                body.append(`${key}[]`, file, file.name);
              }
            } else {
              body.append(key, item);
            }
          });
          return { ...options, body };
        }
      });
    }
  }

  headers['Content-Type'] = 'application/json';

  return method === 'GET'
    ? {
        ...options,
        headers,
        credentials: 'include',
      }
    : {
        ...options,
        headers,
        credentials: 'include',
        body: JSON.stringify(values),
      };
}

export function normalizeQueryString(query) {
  return Object.keys(query).reduce((result, key) => {
    if (Array.isArray(query[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = query[key].join(',');
    } else if (query[key] instanceof Object) {
      return Promise.reject(new Error('Type object is not supported for query string!'));
    } else {
      // eslint-disable-next-line no-param-reassign
      result[key] = query[key];
    }
    return result;
  }, {});
}

export function getApiUrl(fullUrl, query, cache) {
  const urlObj = url.parse(fullUrl);
  const pathname = urlObj.pathname;
  let protocol = urlObj.protocol;
  let hostname = urlObj.hostname;
  let port = urlObj.port;

  const normalizedQuery = query
    ? normalizeQueryString(
        cache ? query : { ...query, _: new Date().getTime() + Math.floor(Math.random() * 1000) },
      )
    : '';
  return url.format({
    protocol,
    hostname,
    port,
    pathname,
    query: normalizedQuery,
  });
}

export function noticeApiStatusError(url, req, res, json, traceId, spanId) {
  ['50', '40'].some(status => {
    const statusRegex = RegExp(`^${status}`);
    if (statusRegex.test(String(res.status))) {
      noticeApiError(`API ${status}x Error`, {
        url,
        req,
        res,
        json,
        traceId,
        spanId,
      });
      return true;
    }
    return false;
  });
}
