import { Store } from 'typesafe-actions';

import { setCommonError } from 'store/commonError/actions';

type ResponseInterceptorOptions = {
  response: Response;
  resolve: (value: unknown) => void;
  reject: (reason?: unknown) => void;
};

const responseInterceptor = (store: Store) => ({ response }: ResponseInterceptorOptions) => {
  // additional handler for maintenance mode
  if (response.status === 503) {
    store.dispatch(
      setCommonError({ message: 'Service is unavailable', httpStatusCode: response.status }),
    );
  } else if (response.status === 408) {
    store.dispatch(setCommonError({ message: 'Network error', httpStatusCode: response.status }));
  }
};

export default responseInterceptor;
