import React, { createContext } from 'react';

const UIComponentContext = createContext({
  ssrDeviceType: 'sp',
  /**
   * #### why we need LinkComponent
   * We need to use this component to render links, because we need to render different component in different applications.
   * Like we might want to simply render <a> for some applications, or render a custom <Link> component to do navigation
   * on client side only for some other applications.
   * #### How to use
   * 1. in your component, `import UIComponentContext from '../../contexts';`
   * 2. in the render function, `const { LinkComponent } = useContext(UIComponentContext);`
   * 3. render by `<LinkComponent href='http://this.is.an.example/link'>example link</LinkComponent>;`
   */
  LinkComponent: (props: React.HTMLProps<HTMLAnchorElement> & { children: React.ReactNode }) => (
    <a {...props}>{props.children}</a>
  ),
});

export default UIComponentContext;
