export enum OFFER_TYPE {
  COUPON = 'COUPON',
  DIRECT = 'DIRECT',
}

export enum INCENTIVE_METHOD {
  POINT = 'POINT',
  DISCOUNT = 'DISCOUNT',
}

export enum INCENTIVE_CALCULATION_METHOD {
  PERCENT = 'PERCENT',
  EXACT_AMOUNT = 'EXACT_AMOUNT',
}

export enum EXPIRATION_REASONS {
  INACTIVE_SP = 'INACTIVE_SP',
  TOTAL_USAGE_EXCEEDED = 'TOTAL_USAGE_EXCEEDED',
  MEMBER_USAGE_EXCEEDED = 'MEMBER_USAGE_EXCEEDED',
  INVALID_PROVIDER_NEW_USER = 'INVALID_PROVIDER_NEW_USER',
  INVALID_LUXURY_NEW_USER = 'INVALID_LUXURY_NEW_USER',
  INVALID_STANDARD_NEW_USER = 'INVALID_STANDARD_NEW_USER',
  INSUFFICIENT_BUDGET = 'INSUFFICIENT_BUDGET',
}

export const EXPIRED_REASON_LABELS: Record<EXPIRATION_REASONS, string> = {
  INACTIVE_SP: 'Coupon_List.Expired.Period_Finished',
  TOTAL_USAGE_EXCEEDED: 'Coupon_List.Expired.Total_Cap_Finished',
  MEMBER_USAGE_EXCEEDED: 'Coupon_List.Expired.User_Cap_Finished',
  INVALID_PROVIDER_NEW_USER: 'Coupon_List.Expired.First_Time_Hotel',
  INVALID_LUXURY_NEW_USER: 'Coupon_List.Expired.First_Time_Platform',
  INVALID_STANDARD_NEW_USER: 'Coupon_List.Expired.First_Time_Platform',
  INSUFFICIENT_BUDGET: 'Coupon_List.Expired.Total_Cap_Finished',
};

export enum PROMOTION_CLASS {
  MAIN = 'MAIN',
  SUB = 'SUB',
}

export enum ACQUIRED_STATUS {
  APPLIED_AUTOMATICALLY = 'appliedAutomatically',
  ACQUIRED_BY_USER = 'acquiredByUser',
  NOT_ACQUIRED_BY_USER = 'notAcquiredByUser',
}

export enum PAYMENT_METHODS {
  LOCAL,
  RAKUTEN_PLATFORM,
}
