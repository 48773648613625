import { createAsyncAction, AppThunk } from 'typesafe-actions';
import { getPitari } from './api';

import generateLogger from '@travel/logger';

const logger = generateLogger();

export const fetchPitariAsync = createAsyncAction(
  'FETCH_PITARI_REQUEST',
  'FETCH_PITARI_SUCCESS',
  'FETCH_PITARI_FAILURE',
)<undefined, any, undefined>();

export const fetchPitariIds = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  dispatch(fetchPitariAsync.request());

  const { authCode, pitari } = getState();
  // do not fetch if there is no token or pitari has been fetched
  if (!authCode.item?.token || pitari.item?.pitari_test_upcoming) return;
  try {
    const response = await getPitari(apiClient);
    dispatch(fetchPitariAsync.success(response));
  } catch (error) {
    logger.error(error);
    dispatch(fetchPitariAsync.failure());
  }
};
