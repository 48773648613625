// @flow
import queryString from 'query-string';

// TODO poom: remove any and type with more strict rule
// string | Array<string> | Array<QueryObject> | QueryObject
type QueryObject = { [key: string]: any } | any;
type Query = { [key: string]: string } | any;

export const isEmptyValue = (value: string | null | undefined): boolean =>
  typeof value === 'undefined' || value === null || value === '' || value === 'undefined';

export const isNotEmptyValue = (value: string | null | undefined) => !isEmptyValue(value);

function serialize(object: any, path: string): string {
  if (typeof object !== 'object') {
    return `${path}=${object}`;
  }
  if (object !== null) {
    return Object.keys(object)
      .map(key => {
        if (Number(key).toString() === key) {
          return serialize(object[key], `${path}[${key}]`);
        }
        return serialize(object[key], `${path}.${key}`);
      })
      .join('&');
  }
  return '';
}

export function stringify(object: any): string {
  return Object.keys(object)
    .map(key => {
      const data = object[key];
      if (data === null || data === undefined || data === '') return null;
      return serialize(data, key);
    })
    .filter(isNotEmptyValue)
    .join('&');
}

const isArrayPath = (path: string) => path.includes('[');

/*eslint no-param-reassign: ["error", { "props": false }]*/
function getOrCreate<T>(object: { [key: string]: T } | any, path: string, value: T): any {
  if (object[path]) {
    return object[path];
  }
  object[path] = value;
  return object[path];
}

const handleArray = (
  _currentTarget: QueryObject,
  part: string,
  isLast: boolean,
  value: string | boolean,
  isArray = false,
) => {
  const CONTAINS_ARRAY_REGEX = /(.*)\[(\d+)\]/;
  let currentTarget = _currentTarget;
  const matched = CONTAINS_ARRAY_REGEX.exec(part);
  const [, matchedPart = '', index = ''] = matched || [];
  let skipBuildingPath = false;

  if (isArrayPath(matchedPart)) {
    currentTarget = handleArray(currentTarget, matchedPart, isLast, value, true);
    skipBuildingPath = true;
  }

  if (!skipBuildingPath && currentTarget instanceof Object) {
    // flow refinement
    currentTarget = getOrCreate(currentTarget, matchedPart, []);
  }

  if (!isArray && isLast && currentTarget) {
    currentTarget[index] = value;
    return null;
  }

  if (Array.isArray(currentTarget)) {
    // flow refinement
    currentTarget = getOrCreate(currentTarget, index, isArray ? [] : {});
    return currentTarget;
  }
  return null;
};

function buildChainedObject(target: QueryObject, path: string, value: string | boolean) {
  let currentTarget = target;
  const token = path.split('.');
  const last = token.length - 1;
  token.forEach((part, partIndex) => {
    const isLast = last === partIndex;

    if (isArrayPath(part)) {
      currentTarget = handleArray(currentTarget, part, isLast, value);
    } else if (currentTarget instanceof Object) {
      if (isLast) {
        currentTarget[part] = value;
        return;
      }
      currentTarget = getOrCreate(currentTarget, part, {});
    }
  });
}

export function queryToObject(query: Query, shouldConvert?: boolean): QueryObject {
  const result: any = {};
  Object.keys(query).forEach(key => {
    const _value = query[key];
    let value = (_value === null || _value === undefined ? '' : _value).toString();

    if (shouldConvert) {
      // add here convert logic if needed, like if(typeof _value  === 'number').
      if (_value === 'true') value = true;
      if (_value === 'false') value = false;
    }

    if (!key.includes('.') && !key.includes('[')) {
      result[key] = value;
    } else {
      buildChainedObject(result, key, value);
    }
  });
  return result;
}

export function toQuery(options: Object) {
  return stringify(options)
    .split('&')
    .reduce((result: any, option) => {
      const [key, value] = option.split('=');
      if (isNotEmptyValue(value)) {
        result[key] = value;
      }
      return result;
    }, {});
}

export function toDecodedQuery(encodedOptions: Object) {
  return stringify(encodedOptions)
    .split('&')
    .reduce((result: any, option) => {
      const [key, value] = option.split('=');
      if (isNotEmptyValue(value)) {
        result[key] = decodeURIComponent(value);
      }
      return result;
    }, {});
}

export function parseQueryString(qs: string): any {
  if (typeof qs === 'string') {
    return queryString.parse(qs) || {};
  }
  return qs || {};
}

export function parseCopyAttr(copyAttr: string) {
  if (!copyAttr) return null;
  const { copyId, ...rest } = queryToObject(parseQueryString(copyAttr), true);
  return { id: copyId, attributes: { ...rest } };
}
