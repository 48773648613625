import _DEFAULT from './data/_DEFAULT.json';
import ja_JP from './data/ja-JP.json';
import ko_KR from './data/ko-KR.json';

import en from './data/en.json';
import vi from './data/vi.json';
import zh from './data/zh.json';

const languageConfigMapping = {
  _DEFAULT,
  en,
  'en-US': en,
  vi,
  'vi-VN': vi,
  'ja-JP': ja_JP,
  'ko-KR': ko_KR,
  'zh-CN': zh,
  'zh-TW': zh,
  'zh-HK': zh,
};

export default languageConfigMapping;
