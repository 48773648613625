const appendTagsData = (data: object) => {
  if (!window.tags) window.tags = {};
  window.tags.data = data;
};

const publishRAT = () => {
  console.log('publish RAT tag');
  const tags = window.tags;
  tags.track.appendTag();
  tags.track.load(tags.track.url, () => {
    tags.track.handleRATTagEvent();
  });
};

const publishThirdPartyTags = () => {
  publishRAT();
};

let newrelic: any;
if (typeof window === 'undefined' && process.env.NEW_RELIC_ENABLED === 'true') {
  //@ts-ignore
  newrelic = global.newrelic;
}

const setTransaction = (pathname: string) => {
  if (newrelic && pathname) {
    newrelic.setTransactionName(pathname);
  }
};

const noticeError = (error: any, parameters: any) => {
  if (typeof window !== 'undefined' && window.newrelic) {
    window.newrelic.noticeError(error, parameters);
  } else if (newrelic) {
    newrelic.noticeError(error, parameters);
  }
};

const track = (name: any, parameters: any) => {
  if (typeof window !== 'undefined' && window.newrelic) {
    window.newrelic.addPageAction(name, parameters);
  } else if (newrelic) {
    newrelic.recordCustomEvent(name, parameters);
  }
};

export { appendTagsData, publishThirdPartyTags, track, setTransaction, noticeError };
