import Decimal from 'decimal.js-light';
import React from 'react';
import { useSelector } from 'react-redux';

import { getL10nNumber } from './L10nNumber';

import { getCurrency, getIsGlobalCurrency, getMarket } from '../selectors';
import { getCurrencyDecimals, getCurrencyPatternWithFlag, getCurrencySign } from '../utils/l10n';

export type Props = {
  market?: string;
  currency?: string;
  value: string | number | null;
  locale?: string;
  isGlobalCurrency?: boolean;
  roundPosition?: number | null;
  shouldDisplayOriginalValue?: boolean;
  isJPYAlternative?: boolean;
  className?: string;
};

export const DEFAULT_CURRENCY = 'USD';

export function turnIntoRoundedValue(value: number, roundPosition: number): number {
  if (roundPosition > 0) {
    const exponentialModifier = Math.pow(10, roundPosition);
    const roundedValue = Math.trunc(value * exponentialModifier) / exponentialModifier;

    return roundedValue < 1 ? value : roundedValue;
  } else {
    return value;
  }
}

export function getL10nMoney(
  market: string,
  value: string | number,
  currency = DEFAULT_CURRENCY,
  isGlobalCurrency = false,
  roundPosition?: number | null,
  shouldDisplayOriginalValue = false,
  isJPYAlternative = true,
) {
  const decimalValue = new Decimal(value);
  const absoluteDecimalValue = decimalValue.abs();
  const currencyDecimals = getCurrencyDecimals(currency);

  let formattedValue = getL10nNumber(
    market,
    absoluteDecimalValue,
    currencyDecimals,
    shouldDisplayOriginalValue,
  );

  if (typeof roundPosition === 'number' && !shouldDisplayOriginalValue) {
    if (roundPosition < 0) {
      const roundedDecimalNumber = new Decimal(turnIntoRoundedValue(+value, roundPosition));
      formattedValue = getL10nNumber(market, roundedDecimalNumber.abs());
    } else {
      formattedValue = getL10nNumber(market, absoluteDecimalValue, roundPosition);
    }
  }

  // currency pattern and sign
  const currencyPattern = getCurrencyPatternWithFlag(currency, isJPYAlternative, isGlobalCurrency);
  const currencySign = getCurrencySign(currency, isGlobalCurrency);

  const pattern = decimalValue.gte(0) ? currencyPattern.positive : currencyPattern.negative;

  return pattern.replace('{amount}', formattedValue).replace('{sign}', currencySign);
}

/**
 * Renders localized money value
 *
 * @param market - Market as ISO 3166-1 alpha-3 code
 * @param value - Value to be formatted
 * @param currency - Currency as ISO 4217 code
 * @param isGlobalCurrency - Flag to indicate if currency symbol to be used is global/domestic
 * @param roundPosition - Decimal positions to round the value to
 * @param shouldDisplayOriginalValue - Flag to indicate if value should be displayed as is; roundPosition will be ignored
 * @param isJPYAlternative - Flag to indicate if JPY alternative pattern should be used
 * @param className - Additional class name for styling
 */
function L10nMoney({
  market,
  value,
  currency,
  isGlobalCurrency,
  roundPosition,
  shouldDisplayOriginalValue = false,
  isJPYAlternative = true,
  className,
}: Props) {
  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  const currencyFromStore = useSelector(getCurrency) || '';
  const shouldUseGlobalCurrency = useSelector(getIsGlobalCurrency) || isGlobalCurrency;

  return !value && value !== 0 ? null : (
    <span className={className}>
      {getL10nMoney(
        market || marketFromStore,
        value,
        currency || currencyFromStore,
        shouldUseGlobalCurrency,
        roundPosition,
        shouldDisplayOriginalValue,
        isJPYAlternative,
      )}
    </span>
  );
}

L10nMoney.defaultProps = {
  value: null,
};

export default L10nMoney;
