import { ApiClient } from '@travel/api-client';
import env from '@travel/env';

import { ResponseBody } from 'Banner-Types';

export const fetch = async (http: ApiClient): Promise<ResponseBody> => {
  return http.get(env('BANNER_API_SERVER_URL') as string, {
    ignoreCommonErrorHandler: true,
  });
};
