function env(name) {
  if (typeof window !== 'undefined' && window.__TRAVEL_ENV__) {
    return window.__TRAVEL_ENV__[name];
  } else {
    return process?.env[name];
  }
}

export function extractTravelEnvScript(nonce) {
  const clientEnv = Object.keys(process.env)
    .filter(key => /^UNIVERSAL_/i.test(key))
    .reduce((env, key) => {
      env[key] = process?.env[key] || '';
      return env;
    }, {});

  return `<script ${nonce ? `nonce="${nonce}"` : ''}>window.__TRAVEL_ENV__=${JSON.stringify(
    clientEnv,
  )}</script>`;
}

export default env;
