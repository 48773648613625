import React from 'react';
import { useSelector } from 'react-redux';

import { getDictionary } from '@travel/i18n';
import { as } from '@travel/utils';

import { getTranslation } from '../utils';
import { Dictionary, TranslateProps } from '../utils/types';

export function Translate(props: TranslateProps): JSX.Element {
  const dictionary = useSelector(getDictionary);
  const value = getTranslation(props, as<Dictionary>(dictionary));
  return React.createElement(
    'span',
    {
      'data-object-id': props.id,
      'data-testid': props.id,
      className: props.className,
    },
    React.Children.toArray(value),
  );
}

Translate.defaultProps = {
  data: {},
};
