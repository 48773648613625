import React from 'react';
import { useSelector } from 'react-redux';

import { getL10nNumber } from './L10nNumber';

import { IMPERIAL_SYSTEM } from '../constants';
import { getMarket } from '../selectors';
import { getMeasurementSystem } from '../utils/l10n';

export enum ValueSize {
  AUTO, // select best unit size based on the given value
  MEDIUM, // meters or feet
  LARGE, // kilometers or miles
}

type ApplyDecimalsToType = Array<ValueSize.MEDIUM | ValueSize.LARGE>;

const DEFAULT_DECIMALS = 0; // round to 0 decimals places
const CONVERSION_FEET_TO_MILES = 0.000189394; // 1/5280, 5280 ft = 1 mi
const CONVERSION_METERS_TO_KM = 0.001; // 1/1000, 1000 m = 1 km

type Props = {
  /** Current user market */
  market?: string;
  /** String or Number in METER or FEET from API (value will change based on market automatically) */
  value: string | number;
  /** Types of sizes of the value (default: 'AUTO', 'MEDIUM' (m/feet) and 'LARGE' (km/mile) */
  valueSize?: ValueSize;
  /** Number of decimals to display (default: 0) */
  decimals?: number;
  /** List of value sizes that the decimals option is applied to */
  applyDecimalsTo?: ApplyDecimalsToType;
};

/**
 * Renders localized version of distance provided
 * unit of measurement (meter/feet) is set from provider-details, we don't pass it here
 *  https://git.rakuten-it.com/projects/EVEREST/repos/monterosa-api/browse/docs/provider-details.md
 */
function L10nDistance({
  market,
  value,
  valueSize = ValueSize.AUTO,
  decimals = DEFAULT_DECIMALS,
  applyDecimalsTo = [ValueSize.MEDIUM, ValueSize.LARGE],
}: Props) {
  value = Number(value);

  const marketFromStore = useSelector(getMarket)?.marketCode || '';
  const marketLocale = market || marketFromStore;
  const measurementSystem = getMeasurementSystem(marketLocale);
  const isImperialSystem = measurementSystem === IMPERIAL_SYSTEM;

  const kiloUnits = isImperialSystem ? CONVERSION_FEET_TO_MILES : CONVERSION_METERS_TO_KM;

  /**
   * Returns size of value enum given value
   * value is guaranteed to be in feet or meter only from backend (will not be in miles/km)
   * Detection logic:
   *  multiply by conversion factor
   *    (essentially divide by 1000 for metric and 5280 for imperial)
   *    if > 1, large unit (km/mi)
   *  if not, medium unit (m/ft)
   */
  const detectValueSize = (value: number): ValueSize => {
    const valueInKiloUnits = value * kiloUnits;

    if (valueInKiloUnits >= 1) {
      return ValueSize.LARGE;
    } else {
      return ValueSize.MEDIUM;
    }
  };

  if (valueSize === ValueSize.AUTO) {
    valueSize = detectValueSize(value);
  }
  decimals = valueSize !== ValueSize.AUTO && applyDecimalsTo.includes(valueSize) ? decimals : 0;

  let localizedValue = '';
  let localizedUnit = '';

  switch (valueSize) {
    case ValueSize.LARGE:
      localizedValue = getL10nNumber(marketLocale, value * kiloUnits, decimals);
      localizedUnit = isImperialSystem ? 'mi' : 'km';
      break;

    case ValueSize.MEDIUM:
    default:
      localizedValue = getL10nNumber(marketLocale, value, decimals);
      localizedUnit = isImperialSystem ? 'ft' : 'm';
      break;
  }

  const localizedDistance = `${localizedValue} ${localizedUnit}`;

  return <span>{localizedDistance}</span>;
}

export default L10nDistance;
