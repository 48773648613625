const paths = {
  top: {
    path: '/',
  },
  notFound: {
    path: `/not_found`,
  },
};

export default paths;
