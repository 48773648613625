import { h, ComponentChildren } from 'preact';

import { cx } from '@travel/utils';

import styles from './layout.module.scss';

type Props = {
  className?: string;
  /** Node of children component */
  children: ComponentChildren;
};

function Layout(props: Props) {
  const { className, children, ...rest } = props;
  return (
    <div class={cx(styles.wrapper, className)} {...rest}>
      {children}
    </div>
  );
}

export default Layout;
