import { ApiClient } from '@travel/api-client';
import env from '@travel/env';

const isServer = process.env.UNIVERSAL_ENV === 'server';

function createApiClient() {
  const isMockApiServer = env('UNIVERSAL_API_MOCK_SERVER') === 'true';

  if (isMockApiServer) {
    const mockApiServerURL = env('UNIVERSAL_MOCK_API_SERVER_URL');
    const mockApiClientURL = env('UNIVERSAL_MOCK_API_CLIENT_URL');

    if (!mockApiServerURL || !mockApiClientURL) {
      throw new Error(
        'Environment variable "UNIVERSAL_MOCK_API_SERVER_URL" and "UNIVERSAL_MOCK_API_CLIENT_URL" must be set when starting app with mock server(UNIVERSAL_API_MOCK_SERVER=true)',
      );
    }

    const apiClient = new ApiClient({
      baseURLServer: mockApiServerURL,
      baseURLClient: mockApiClientURL,
      server: isServer,
    });

    return apiClient;
  } else {
    const apiServerUrl = env('UNIVERSAL_API_BASE_URL_SERVER');
    const apiClientUrl = env('UNIVERSAL_API_BASE_URL_CLIENT');
    if (!apiClientUrl || !apiServerUrl) {
      throw new Error(
        'Environment variable "UNIVERSAL_API_BASE_URL_SERVER" and "UNIVERSAL_API_BASE_URL_CLIENT" must be set when starting app with real api(UNIVERSAL_API_MOCK_SERVER=false)',
      );
    }
    const apiClient = new ApiClient({
      baseURLServer: apiServerUrl,
      baseURLClient: apiClientUrl,
      server: isServer,
    });

    return apiClient;
  }
}

export { createApiClient };
