import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { setUserAgent } from './actions';

import { UserAgent } from 'UserAgent-Types';

export const userAgent = createReducer({} as UserAgent).handleAction(
  setUserAgent,
  (_state, action) => action.payload,
);

const userAgentReducer = combineReducers({
  userAgent,
});

export default userAgentReducer;
export type UserAgentState = ReturnType<typeof userAgentReducer>;
