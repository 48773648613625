import { encode } from 'js-base64';
import { createAsyncAction, AppThunk } from 'typesafe-actions';

import generateLogger from '@travel/logger';

import { AuthCodeError, AuthCodeItem } from 'AuthCode-Types';
import { postInHouseToken, postLogout, refreshToken } from './api';

const logger = generateLogger();
export const fetchAuthCodeAsync = createAsyncAction(
  'FETCH_AUTH_CODE_REQUEST',
  'FETCH_AUTH_CODE_SUCCESS',
  'FETCH_AUTH_CODE_FAILURE',
)<undefined, AuthCodeItem, AuthCodeError>();
export const logoutAsync = createAsyncAction('LOGOUT_REQUEST', 'LOGOUT_SUCCESS', 'LOGOUT_FAILURE')<
  undefined,
  AuthCodeItem,
  AuthCodeError
>();

export const fetchAuthCode = (fIdSession: string): AppThunk => async (
  dispatch,
  _getState,
  { apiClient },
) => {
  try {
    dispatch(fetchAuthCodeAsync.request());

    const authCode = await postInHouseToken(apiClient, encode(fIdSession));
    dispatch(fetchAuthCodeAsync.success(authCode));
  } catch (error) {
    dispatch(fetchAuthCodeAsync.failure(error as AuthCodeError));
    logger.info(error);
  }
};

export const logout = (): AppThunk => async (dispatch, _getState, { apiClient }) => {
  try {
    dispatch(logoutAsync.request());
    await postLogout(apiClient);
    dispatch(logoutAsync.success({ token: '' }));
  } catch (error) {
    dispatch(logoutAsync.failure(error as AuthCodeError));
  }
};

export const refreshAuthCode = (): AppThunk => async (dispatch, getState, { apiClient }) => {
  try {
    const {
      _httpRequest: { normalizedCookies },
      authCode: { item },
    } = getState();

    if (!item?.token) return;

    const authCode = await refreshToken(apiClient, encode(normalizedCookies.f_id_session.value));
    dispatch(fetchAuthCodeAsync.success(authCode));
  } catch (error) {
    dispatch(fetchAuthCodeAsync.failure(error as AuthCodeError));
    logger.error(error);
  }
};
