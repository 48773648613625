import { ApiClient } from '@travel/api-client';

export const postInHouseToken = (http: ApiClient, fIdSession: string) =>
  http.post('/consumer/auth-endusers/member-token/current-login-token', {
    ignoreCommonErrorHandler: true,
    headers: {
      fIdSession,
    },
  });

export const postLogout = (http: ApiClient) =>
  http.post('/consumer/auth-endusers/member-token/current-logout-token', {
    ignoreCommonErrorHandler: true,
  });

export const refreshToken = (http: ApiClient, fIdSession: string) =>
  http.get('/consumer/auth-endusers/member-refresh/current-refresh-token', {
    ignoreCommonErrorHandler: true,
    withAuthCode: true,
    headers: {
      fIdSession,
    },
  });
