import { createAsyncAction, createStandardAction, AppThunk } from 'typesafe-actions';
import { getCouponListAPI, postCouponAPI } from './api';

import generateLogger from '@travel/logger';

import { setCommonError } from 'store/commonError/actions';

import { CouponSearchRequestBody } from 'FurusatoNozei-Types';

export const fetchCouponListAsync = createAsyncAction(
  'FETCH_FURUSATO_NOZEI_COUPON_REQUEST',
  'FETCH_FURUSATO_NOZEI_COUPON_SUCCESS',
  'FETCH_FURUSATO_NOZEI_COUPON_FAILURE',
)<undefined, any, any>();

export const submitCouponAsync = createAsyncAction(
  'SUBMIT_FURUSATO_NOZEI_COUPON_REQUEST',
  'SUBMIT_FURUSATO_NOZEI_COUPON_SUCCESS',
  'SUBMIT_FURUSATO_NOZEI_COUPON_FAILURE',
)<undefined, any, any>();

export const closeCouponSelection = createStandardAction('CLOSE_COUPON_SELECTION_DIALOG')();
export const closeFailSubmitCouponDialog = createStandardAction(
  'CLOSE_FAIL_SUBMIT_COUPON_DIALOG',
)();
const logger = generateLogger();

export const fetchCouponList = (params: CouponSearchRequestBody): AppThunk => async (
  dispatch,
  _getState,
) => {
  dispatch(fetchCouponListAsync.request());

  try {
    const response = await getCouponListAPI(params);
    if (!response.ok) {
      const error = await response.json();
      logger.error('error', error);
      dispatch(fetchCouponListAsync.failure(error));
      dispatch(setCommonError({ message: 'test error message', httpStatusCode: response.status }));
    }
    const { results, errors } = await response.json();
    if (errors) {
      logger.error(errors);
      dispatch(fetchCouponListAsync.failure({ ...errors, reservationId: params.reservationId }));
    } else {
      dispatch(fetchCouponListAsync.success(results));
    }
  } catch (error) {
    logger.error(error);
    dispatch(fetchCouponListAsync.failure(error));
  }
};

export const submitCoupon = (params: {
  couponCode: string;
  discountedPrice: number;
}): AppThunk => async (dispatch, getState) => {
  dispatch(submitCouponAsync.request());
  const state = getState();
  const reservationId = state.furusatoNozei.couponList.reservationId;
  const requestBody = { ...params, reservationId };

  try {
    const response = await postCouponAPI(requestBody);
    if (!response.ok) {
      const error = await response.json();
      logger.error(error);
      dispatch(submitCouponAsync.failure(error));
    }
    const { errors, results } = await response.json();
    if (errors) {
      logger.error(errors);
      dispatch(submitCouponAsync.failure(errors));
    } else {
      dispatch(submitCouponAsync.success(results));
    }
  } catch (error) {
    logger.error(error);
    dispatch(submitCouponAsync.failure(error));
  }
};
