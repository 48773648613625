import * as types from './action';

const initialState = {
  normalizedCookies: {},
};

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case types.SET_NORMALIZED_COOKIES:
      return {
        ...state,
        normalizedCookies: action.payload,
      };
    default:
      return state;
  }
}
