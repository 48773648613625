import React, { ReactNode, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ariaOnKeyDown, cx } from '@travel/utils';
import { isMacOs } from '@travel/traveler-core/utils';

import { pushLocation } from 'store/__router/actions';
import { getHost, getProtocol } from 'store/__router/selectors';

import styles from './link.module.scss';
import { getLanguage, getMarket } from '@travel/i18n';

type Props = {
  to: string;
  children: ReactNode;
  isLink?: boolean;
} & React.HTMLProps<HTMLAnchorElement>;

export const getHref = (
  host: string,
  to: string,
  language: string,
  marketCode: string,
  protocol?: string,
) => {
  return `${protocol ? `${protocol}://` : ''}${host}${to}`;
};

export function Link(props: Props) {
  const { to, className, isLink, href, ...restProps } = props;
  const dispatch = useDispatch();
  const host = useSelector(getHost);
  const protocol = useSelector(getProtocol);
  const language = useSelector(getLanguage);
  const market = useSelector(getMarket);

  const handleClick = (e: MouseEvent | React.KeyboardEvent<HTMLAnchorElement>) => {
    const openInNewTab = isMacOs() ? e.metaKey : e.ctrlKey;
    if (!openInNewTab && props.onClick) {
      props.onClick(e as MouseEvent<HTMLAnchorElement>);
    } else if (!openInNewTab) {
      e.preventDefault();
      dispatch(pushLocation(to));
    }
  };

  return (
    <a
      className={cx(!isLink && styles.wrapper, className)}
      {...restProps}
      onClick={handleClick}
      href={href || getHref(host, to, language, market.marketCode, protocol)}
      role="button"
      onKeyDown={ariaOnKeyDown(
        // incase a onClick prop is added we don't run the handleClick function
        (props.onClick as (e: MouseEvent | React.KeyboardEvent<HTMLAnchorElement>) => void) ||
          handleClick,
      )}
      tabIndex={0}
    >
      {props.children}
    </a>
  );
}

export default Link;
