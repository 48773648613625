import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import env from '@travel/env';
import { useTranslation } from '@travel/traveler-core/hooks';
import useMemberKeepAlive from '@travel/traveler-core/hooks/useMemberKeepAlive';

import ChatBox from 'components/ChatBox';
import Layout from 'components/Layout';
import Pitari from 'components/Pitari';
import useHeaderEvents from 'hooks/useHeaderEvents';
import ErrorPage from 'pages/ErrorPage';
import { refreshAuthCode } from 'store/authCode/actions';
import { getIsLoggedIn } from 'store/authCode/selectors';
import { getIsAPIError } from 'store/commonError/selectors';
import SEO from './components/SEO';

import { pushLocation } from 'store/__router/actions';

import { SITE_IMAGE_URL } from 'constants/common';

import styles from '../style/app.module.scss';

const App = (props: any) => {
  const dispatch = useDispatch();

  const isAPIError = useSelector(getIsAPIError);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const normalPageTitle = useTranslation({ id: 'Booking_List.Header.HTML_Title' });
  // refresh token
  const refreshTokenHookCallback = useCallback(() => {
    dispatch(refreshAuthCode());
  }, [dispatch]);

  useHeaderEvents();
  useMemberKeepAlive(isLoggedIn, refreshTokenHookCallback);

  const envName = env('UNIVERSAL_ENV_NAME');

  let content = props.children;
  if (isAPIError) content = <ErrorPage />;

  return (
    <div id="app" data-testid="app-wrapper" className={styles.appWrapper}>
      <SEO title={normalPageTitle} imageUrl={SITE_IMAGE_URL} />
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: '<global-header />' }}
      />
      {envName && (
        <button
          data-testid="app-env-button"
          class={styles.envButton}
          tabIndex={-1}
          onClick={() => {
            dispatch(
              pushLocation(
                window.location.pathname.replace(env('UNIVERSAL_BASE_NAME') as string, '') +
                  window.location.search,
              ),
            );
          }}
        >
          {envName}
        </button>
      )}
      <Layout className={styles.layout}>{content}</Layout>
      {isLoggedIn && <Pitari />}
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: '<global-footer />' }} />
      <ChatBox />
    </div>
  );
};

export default App;
