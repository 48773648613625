import { as } from '@travel/utils';

import { fetchBanner } from 'store/banner/actions';
import { fetchPitariIds } from 'store/pitari/actions';
import { fetchReservationList } from 'store/reservationsList/actions';

import { ServiceType, Status } from 'constants/reservations';
import { universalRouterProps } from 'core/universalRouter/types';
import { SearchQuery } from 'ReservationsList-Types';

export default ({ store: { dispatch } }: universalRouterProps, query: SearchQuery) => {
  const req = {
    reservationList: query.query?.reservationList || Status.UPCOMING,
    period: query.query?.period,
    serviceType: query.query?.serviceType
      ? [...as<Array<ServiceType>>(query.query?.serviceType.split(','))]
      : undefined,
    offset: 0,
    limit: 10,
  };
  return Promise.all([
    dispatch(fetchReservationList(req)),
    dispatch(fetchPitariIds()),
    dispatch(fetchBanner()),
  ]);
};
