import { h } from 'preact';
import Helmet from 'preact-helmet';

import { LANGUAGE_CODE } from 'constants/common';

type Props = {
  title?: string;
  description?: string;
  keywords?: string[];
  imageUrl?: string;
};

const SEO = (props: Props) => {
  const { title, description, keywords, imageUrl } = props;

  const metaContents = [
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    {
      name: 'robots',
      content: 'noindex, nofollow, noarchive',
    },
  ];

  if (description) {
    metaContents.push(
      { name: 'description', content: description },
      { property: 'og:description', content: description },
    );
  }

  if (imageUrl) metaContents.push({ property: 'og:image', content: imageUrl });
  if (keywords) metaContents.push({ name: 'keywords', content: keywords?.join(',') });
  return (
    <Helmet
      htmlAttributes={{ lang: LANGUAGE_CODE, amp: undefined }}
      title={title}
      meta={metaContents}
    />
  );
};

export default SEO;
