import { ApiClient } from '@travel/api-client';

import { RequestBody, ResponseBody } from 'ReservationsList-Types';

export const getReservationsListAPI = async (
  http: ApiClient,
  params: RequestBody,
): Promise<ResponseBody> => {
  return http.post('/consumer/mypage/current/reservation/list', { values: params });
};
